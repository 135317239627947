import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import EderSparkLogo from '../assets/ederspark-flame.png';
import { HiSparkles, HiUserGroup } from "react-icons/hi2";
import { IoRocketSharp, IoBusinessSharp, IoRocketOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { MainUserContext } from '../App';
import { Switch } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import { HiMail } from "react-icons/hi";
import Input from '@mui/joy/Input';
import axios from 'axios';

export default function Subscribe() {
    const { user } = React.useContext(MainUserContext);
    const [isYearly, setIsYearly] = React.useState(false);
    const [comingSoonModal, setComingSoonModal] = React.useState(false);
    const [requestModal, setRequestModal] = React.useState(false);
    const [institution, setInstitution] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [submitSuccess, setSubmitSuccess] = React.useState(false);

    const prices = {
        personal: {
            monthly: 24.95,
            yearly: 19.96, // 20% discount
        },
        expert: {
            monthly: 64.95,
            yearly: 51.96,
        },
        apex: {
            monthly: 99.95,
            yearly: 79.96,
        }
    };

    const personalFeatures = [
        "Unlimited AI conversations",
        "Unlimited Freiya Mini messages",
        "100 Pro messages per month",
        "Focus mode for paper analysis",
        "Active support"
    ];

    const expertFeatures = [
        "Everything in Personal plan",
        "Unlimited Pro messages per month",
        "50 NOVA messages per month",
        "Advanced research capabilities",
        "24/7 support"
    ];

    const apexFeatures = [
        "Everything in Expert plan",
        "Unlimited NOVA messages", 
        "Highest priority in queue",
        "Suggest new features",
        "Priority access to new features"
    ];

    const handleSubscribeClick = (e) => {
        e.preventDefault();
        setComingSoonModal(true);
    };

    const handleRequestClick = (e) => {
        e.preventDefault();
        setRequestModal(true);
    };

    const handleInstitutionalRequest = async (e) => {
        e.preventDefault();
        
        // Validate input
        if (!institution.trim()) {
            setError('Please enter your institution name');
            return;
        }

        setIsSubmitting(true);
        setError('');

        try {
            const emailBody = `
                Institutional License Request
                --------------------------
                User: ${user?.mail}
                Institution: ${institution}
                Date: ${new Date().toLocaleString()}
            `;

            const emailHtml = `
                <h2>Institutional License Request</h2>
                <p><strong>User:</strong> ${user?.mail}</p>
                <p><strong>Institution:</strong> ${institution}</p>
                <p><strong>Date:</strong> ${new Date().toLocaleString()}</p>
            `;

            await axios.post('/api/send-mail', {
                to: 'support@ederspark.com',
                subject: `Institutional License Request - ${institution}`,
                text: emailBody,
                html: emailHtml
            });

            setSubmitSuccess(true);
            setInstitution('');
            setTimeout(() => {
                setRequestModal(false);
                setSubmitSuccess(false);
            }, 2000);

        } catch (error) {
            setError('Failed to send request. Please try again later.');
            console.error('Error sending request:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    {/* Header Section */}
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-center mb-8"
                    >
                        <div className="flex justify-center mb-6">
                            <div className="relative">
                                <img 
                                    src={EderSparkLogo} 
                                    alt="EderSpark" 
                                    className="w-10" 
                                />
                                <motion.div 
                                    animate={{ 
                                        scale: [1, 1.2, 1],
                                        opacity: [0.5, 0.8, 0.5]
                                    }}
                                    transition={{ 
                                        duration: 2,
                                        repeat: Infinity,
                                        ease: "easeInOut"
                                    }}
                                    className="absolute -inset-2 bg-emerald-100 rounded-full -z-10"
                                />
                            </div>
                        </div>
                        <h1 className="text-4xl mb-4 bg-gradient-to-r from-emerald-600 to-green-600 bg-clip-text text-transparent">
                            Unlock the <strong>Full Power</strong> of Freiya
                        </h1>
                        <p className="text-lg text-gray-600 mb-4">
                            Welcome back, <span className="font-semibold">{user?.mail}</span>
                        </p>
                        <div className="inline-flex items-center gap-2 px-4 py-2 bg-amber-50 rounded-full">
                            <HiSparkles className="w-5 h-5 text-amber-500" />
                            <span className="text-sm text-amber-600">Free trial expired</span>
                        </div>
                    </motion.div>

                    {/* Billing Toggle */}
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        className="flex flex-col items-center justify-center mb-16"
                    >
                        <div className="flex items-center gap-4">
                            <span className={`text-sm font-medium ${!isYearly ? 'text-gray-900' : 'text-gray-500'}`}>Monthly</span>
                            <Switch
                                checked={isYearly}
                                onChange={() => setIsYearly(!isYearly)}
                                sx={{
                                    '--Switch-thumb-size': '24px',
                                    '--Switch-track-width': '64px',
                                    '--Switch-track-height': '32px',
                                    '&:hover': {
                                        '--Switch-track-background': 'var(--joy-palette-primary-500)',
                                    },
                                }}
                            />
                            <span className={`text-sm font-medium ${isYearly ? 'text-gray-900' : 'text-gray-500'}`}>
                                Yearly
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Save 20%
                                </span>
                            </span>
                        </div>
                    </motion.div>

                    {/* Main Content - Updated for three plans */}
                    <div className="grid md:grid-cols-3 gap-8 items-start max-w-7xl mx-auto">
                        {/* Personal Plan Card */}
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2 }}
                            className="bg-white p-8 rounded-2xl shadow-sm border border-gray-100 relative"
                        >
                            <div className="text-center mb-8">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Personal Plan
                                </h3>
                                <div className="flex justify-center items-baseline mb-4">
                                    <span className="text-5xl font-bold text-emerald-600">
                                        {isYearly ? prices.personal.yearly : prices.personal.monthly}€
                                    </span>
                                    <span className="text-gray-500 ml-2">/{isYearly ? 'month' : 'month'}</span>
                                </div>
                                {isYearly && (
                                    <div className="text-sm text-gray-500">
                                        Billed annually at {prices.personal.yearly * 12}€
                                    </div>
                                )}
                                <p className="text-gray-600 text-sm">
                                    Perfect for individual researchers
                                </p>
                            </div>

                            <div className="space-y-4 mb-8">
                                {personalFeatures.map((feature, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.3 + index * 0.1 }}
                                        className="flex items-center gap-3"
                                    >
                                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-100 flex items-center justify-center">
                                            <FaCheck className="w-3 h-3 text-emerald-600" />
                                        </div>
                                        <span className="text-gray-700">{feature}</span>
                                    </motion.div>
                                ))}
                            </div>

                            <motion.button
                                onClick={handleSubscribeClick}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="w-full py-4 px-6 rounded-xl bg-gradient-to-r from-emerald-600 to-green-600 text-white font-semibold shadow-lg shadow-emerald-200 hover:shadow-xl hover:shadow-emerald-200 transition-all duration-200 flex items-center justify-center gap-2 group"
                            >
                                <IoRocketSharp className="w-5 h-5 group-hover:animate-bounce" />
                                Choose Personal
                            </motion.button>
                        </motion.div>

                        {/* Expert Plan Card */}
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.3 }}
                            className="bg-gradient-to-br from-orange-50 via-amber-50 to-yellow-50 p-8 rounded-2xl shadow-sm border border-orange-100 relative"
                        >
                            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                                <span className="px-4 py-1.5 bg-gradient-to-r from-orange-500 to-amber-500 text-white text-sm font-semibold rounded-full shadow-lg">
                                    Most Popular
                                </span>
                            </div>

                            <div className="text-center mb-8">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Expert Plan
                                </h3>
                                <div className="flex justify-center items-baseline mb-4">
                                    <span className="text-5xl font-bold bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">
                                        {isYearly ? prices.expert.yearly : prices.expert.monthly}€
                                    </span>
                                    <span className="text-gray-500 ml-2">/{isYearly ? 'month' : 'month'}</span>
                                </div>
                                {isYearly && (
                                    <div className="text-sm text-gray-500">
                                        Billed annually at {prices.expert.yearly * 12}€
                                    </div>
                                )}
                                <p className="text-gray-600 text-sm">
                                    For power users and professionals
                                </p>
                            </div>

                            <div className="space-y-4 mb-8">
                                {expertFeatures.map((feature, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.3 + index * 0.1 }}
                                        className="flex items-center gap-3"
                                    >
                                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-orange-100 flex items-center justify-center">
                                            <FaCheck className="w-3 h-3 text-orange-600" />
                                        </div>
                                        <span className="text-gray-700">{feature}</span>
                                    </motion.div>
                                ))}
                            </div>

                            <motion.button
                                onClick={handleSubscribeClick}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="w-full py-4 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-amber-500 text-white font-semibold shadow-lg shadow-orange-200 hover:shadow-xl hover:shadow-orange-200 transition-all duration-200 flex items-center justify-center gap-2 group"
                            >
                                <HiSparkles className="w-5 h-5 group-hover:animate-spin" />
                                Choose Expert
                            </motion.button>
                        </motion.div>

                        {/* APEX Plan Card */}
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.4 }}
                            className="bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 p-8 rounded-2xl shadow-sm border border-indigo-100 relative transform hover:scale-105 transition-transform duration-300"
                        >
                            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                                <span className="px-4 py-1.5 bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm font-semibold rounded-full shadow-lg">
                                    Ultimate Power
                                </span>
                            </div>

                            <div className="text-center mb-8">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    APEX Plan
                                </h3>
                                <div className="flex justify-center items-baseline mb-4">
                                    <span className="text-5xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                                        {isYearly ? prices.apex.yearly : prices.apex.monthly}€
                                    </span>
                                    <span className="text-gray-500 ml-2">/{isYearly ? 'month' : 'month'}</span>
                                </div>
                                {isYearly && (
                                    <div className="text-sm text-gray-500">
                                        Billed annually at {prices.apex.yearly * 12}€
                                    </div>
                                )}
                                <p className="text-gray-600 text-sm">
                                    For enterprises and power users
                                </p>
                            </div>

                            <div className="space-y-4 mb-8">
                                {apexFeatures.map((feature, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.3 + index * 0.1 }}
                                        className="flex items-center gap-3"
                                    >
                                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-indigo-100 flex items-center justify-center">
                                            <FaCheck className="w-3 h-3 text-indigo-600" />
                                        </div>
                                        <span className="text-gray-700">{feature}</span>
                                    </motion.div>
                                ))}
                            </div>

                            <motion.button
                                onClick={handleSubscribeClick}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="w-full py-4 px-6 rounded-xl bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-semibold shadow-lg shadow-indigo-200 hover:shadow-xl hover:shadow-indigo-200 transition-all duration-200 flex items-center justify-center gap-2 group"
                            >
                                <HiSparkles className="w-5 h-5 group-hover:animate-[spin_1s_ease-in-out_infinite]" />
                                Choose NOVA
                            </motion.button>
                        </motion.div>
                    </div>

                    {/* Institutional Licensing Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5 }}
                        className="mt-20 max-w-4xl mx-auto"
                    >
                        <div className="bg-gradient-to-br from-blue-50 via-indigo-50 to-blue-50 rounded-2xl p-8 border border-blue-100">
                            <div className="flex flex-col md:flex-row items-center gap-8">
                                {/* Left side - Icon and Title */}
                                <div className="flex-shrink-0 text-center md:text-left">
                                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-xl bg-blue-100 mb-4">
                                        <IoBusinessSharp className="w-8 h-8 text-blue-600" />
                                    </div>
                                    <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                        Institutional Access
                                    </h3>
                                    <div className="flex items-center gap-2 text-blue-600 text-sm font-medium mb-4">
                                        <HiUserGroup className="w-4 h-4" />
                                        <span>For Teams & Organizations</span>
                                    </div>
                                </div>

                                {/* Right side - Content */}
                                <div className="flex-1 space-y-4">
                                    <p className="text-gray-600">
                                        Are you part of a research institution, university, or organization? Send us an email telling us about your institution and we'll be happy to discuss custom licensing options with them for you.
                                    </p>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                                        <div className="flex items-center gap-2">
                                            <FaCheck className="w-4 h-4 text-blue-500" />
                                            <span className="text-sm text-gray-600">Volume discounts</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FaCheck className="w-4 h-4 text-blue-500" />
                                            <span className="text-sm text-gray-600">Centralized billing</span>
                                        </div>
                                    </div>

                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        onClick={handleRequestClick}
                                        className="flex-1 py-3 px-6 rounded-xl bg-blue-600 text-white font-semibold shadow-lg shadow-blue-200 hover:shadow-xl hover:shadow-blue-200 transition-all duration-200 flex items-center justify-center gap-2"
                                    >
                                        Make a request
                                    </motion.button>

                                    <p className="text-sm text-gray-500 text-center sm:text-left">
                                        Already have an institutional code? <a href="/redeem" className="text-blue-600 hover:text-blue-700 font-medium">Redeem here</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    {/* Additional Info */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6 }}
                        className="mt-16 text-center"
                    >
                        <p className="text-sm text-gray-500 mb-4">
                            All plans include:
                        </p>
                        <div className="flex flex-wrap justify-center gap-8 mb-8 text-sm text-gray-600">
                            <span className="flex items-center gap-2">
                                <FaCheck className="text-emerald-500" />
                                14-day money-back guarantee
                            </span>
                            <span className="flex items-center gap-2">
                                <FaCheck className="text-emerald-500" />
                                Cancel anytime
                            </span>
                            <span className="flex items-center gap-2">
                                <FaCheck className="text-emerald-500" />
                                Priority support
                            </span>
                            <span className="flex items-center gap-2">
                                <FaCheck className="text-emerald-500" />
                                Regular feature updates
                            </span>
                        </div>
                        
                        <Link 
                            to="/contact" 
                            className="text-sm text-emerald-600 hover:text-emerald-700 transition-colors"
                        >
                            Need help? Contact us
                        </Link>
                    </motion.div>
                </div>
            </div>

            {/* Request Modal */}
            <Modal
                aria-labelledby="request-modal-title"
                open={requestModal}
                onClose={() => {
                    if (!isSubmitting) {
                        setRequestModal(false);
                        setError('');
                        setInstitution('');
                        setSubmitSuccess(false);
                    }
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    className="bg-white rounded-2xl p-6 max-w-md mx-4 relative shadow-xl"
                >
                    <ModalClose 
                        variant="plain" 
                        disabled={isSubmitting}
                        sx={{ 
                            position: 'absolute', 
                            top: '0.75rem', 
                            right: '0.75rem',
                            '&:hover': { 
                                background: 'rgba(0,0,0,0.04)' 
                            }
                        }} 
                    />

                    <div className="flex flex-col items-center text-center gap-4">
                        <div className="w-16 h-16 rounded-full bg-blue-50 flex items-center justify-center mb-2">
                            <IoBusinessSharp className="w-8 h-8 text-blue-600" />
                        </div>

                        {submitSuccess ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                className="text-center"
                            >
                                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Request Sent Successfully!
                                </h2>
                                <p className="text-gray-600">
                                    We'll get back to you soon.
                                </p>
                            </motion.div>
                        ) : (
                            <form onSubmit={handleInstitutionalRequest} className="w-full">
                                <h2 className="text-2xl font-semibold text-gray-800 mb-2" id="request-modal-title">
                                    Request Institutional Access
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    Please enter your institution or company name
                                </p>

                                <div className="space-y-4">
                                    <Input
                                        value={institution}
                                        onChange={(e) => setInstitution(e.target.value)}
                                        placeholder="Institution name"
                                        disabled={isSubmitting}
                                        error={!!error}
                                        className="w-full"
                                    />
                                    
                                    {error && (
                                        <p className="text-sm text-red-600">
                                            {error}
                                        </p>
                                    )}

                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="w-full py-3 px-6 rounded-xl bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-semibold shadow-lg shadow-blue-200 hover:shadow-xl hover:shadow-blue-200 transition-all duration-200 flex items-center justify-center gap-2 disabled:opacity-50"
                                    >
                                        {isSubmitting ? (
                                            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                        ) : (
                                            'Submit Request'
                                        )}
                                    </motion.button>
                                </div>
                            </form>
                        )}
                    </div>
                </motion.div>
            </Modal>

            {/* Coming Soon Modal */}
            <Modal
                aria-labelledby="coming-soon-modal-title"
                open={comingSoonModal}
                onClose={() => setComingSoonModal(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    className="bg-white rounded-2xl p-6 max-w-md mx-4 relative shadow-xl"
                >
                    <ModalClose 
                        variant="plain" 
                        sx={{ 
                            position: 'absolute', 
                            top: '0.75rem', 
                            right: '0.75rem',
                            '&:hover': { 
                                background: 'rgba(0,0,0,0.04)' 
                            }
                        }} 
                    />

                    <div className="flex flex-col items-center text-center gap-4">
                        <div className="w-16 h-16 rounded-full bg-emerald-50 flex items-center justify-center mb-2">
                            <motion.div
                                animate={{ 
                                    y: [0, -5, 0],
                                    rotate: [0, 10, -10, 0]
                                }}
                                transition={{ 
                                    duration: 2,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                            >
                                <IoRocketOutline className="w-8 h-8 text-emerald-600" />
                            </motion.div>
                        </div>

                        <div>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2" id="coming-soon-modal-title">
                                Thank you for your interest!
                            </h2>
                            <p className="text-gray-600">
                                We're working hard to bring you this feature soon. Stay tuned!
                            </p>
                        </div>

                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={() => setComingSoonModal(false)}
                            className="mt-4 px-6 py-2 bg-gradient-to-r from-emerald-600 to-green-600 text-white rounded-lg font-medium shadow-lg shadow-emerald-100 hover:shadow-xl hover:shadow-emerald-200 transition-all duration-200"
                        >
                            Got it
                        </motion.button>
                    </div>
                </motion.div>
            </Modal>
        </>
    );
} 