import * as React from 'react';
import { useEffect } from "react";
import axios from "axios";
import { MainUserContext } from "../App";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewsModal from "../components/NewsModal";

import { Helmet } from 'react-helmet';
import RightSideMenu from "../common_components/RightSideMenu";

import FeedbackButtons from "../common_components/FeedbackButtons";
import ChatContent from "../components/ChatContent";

import SparkaiSidebar from "../components/SparkaiSidebar";
import { checkAuthStatus, fetchUserData } from '../services/authService';
import Subscribe from './Subscribe';
export const UserContext = React.createContext(null);

const messageLoadingDetailsList = [
    "Translating text",
    "Reading the whole paper",
    "Gathering relevant information",
    "Sending information",
    "Synthesizing the answer",
    "Searching for your ideal paper",
];

export default function Chat({openTutorial}) {
    const { userId } = React.useContext(MainUserContext);
    const [searchParams] = useSearchParams();
    const chatId = searchParams.get('chat_id');

    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);

    const [isOpen, setIsOpen] = React.useState(true);
    const [enableChatInput, setEnableChatInput] = React.useState(false);
    const [currentChatId, setCurrentChatId] = React.useState(null);

    const [messageIsLoading, setMessageIsLoading] = React.useState(false);
    const [messageIsLoadingDetails, setMessageIsLoadingDetails] = React.useState("Searching for your ideal paper");

    const [messages, setMessages] = React.useState([]);
    const [chats, setChats] = React.useState([]);

    const [openSources, setOpenSources] = React.useState(false);
    const [sources, setSources] = React.useState([]);
    const [ newSourcesNotification, setNewSourcesNotification ] = React.useState(false);

    const [ sourcesDrawerOpen, setSourcesDrawerOpen ] = React.useState(false);
    const [ messageIdForSources, setMessageIdForSources ] = React.useState(null);

    const [ sparkaiSidebarOpen, setSparkaiSidebarOpen ] = React.useState(true);
    const [ marginLeft, setMarginLeft ] = React.useState(0);

    const [ showSurvey, setShowSurvey ] = React.useState(false);

    const [ model, setModel ] = React.useState('smart');

    const [ showNewsModal, setShowNewsModal ] = React.useState(false);
    const [ hasUnreadNews, setHasUnreadNews ] = React.useState(true);

    const [isValidatingChat, setIsValidatingChat] = React.useState(true);

    const { isSubscribed } = React.useContext(MainUserContext);
    const [ showSubscribe, setShowSubscribe ] = React.useState(false);

    const [ chatInput, setChatInput ] = React.useState('');

    React.useEffect(() => {
        const validateChat = async () => {
            if (chatId && userId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chat/check_conversation/${chatId}&${userId}`);
                    if (!response.data.isOwner) {
                        window.location.replace('/chat');
                    }
                } catch (error) {
                    console.error('Error validating chat ownership:', error);
                    window.location.replace('/chat');
                }
            }
            setIsValidatingChat(false);
        };

        validateChat();
    }, [chatId, userId]);

    React.useEffect(() => {
        if (window.innerWidth < 768) {
            setSparkaiSidebarOpen(false);
        }
    }, []);

    /**
     * If messageIsLoading is true, messageIsLoadingDetails will be updated every 2 seconds,
     * cycling through the messageLoadingDetailsList array in loop.
     */

    React.useEffect(() => {
        let interval;
        if (messageIsLoading) {
            let i = 0;
            interval = setInterval(() => {
                setMessageIsLoadingDetails(messageLoadingDetailsList[i]);
                i = (i + 1) % messageLoadingDetailsList.length;
            }, 3500); // Run every 2 seconds
        }
        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [messageIsLoading]);

    React.useEffect(() => {
        const validateAuth = async () => {
            try {
                const user = await checkAuthStatus();
                
                if (user) {
                    const userData = await fetchUserData(user.mail);
                    setUser(userData);
                    setUserId(userData.user_id);
                    setLoggedIn(true);
                    setEmail(userData.mail);
                    console.log('isSubscribed:', isSubscribed);
                    if (!userData.subscribed) {
                        console.log('isSubscribed is false');
                        setShowSubscribe(true);
                    }
                } else {
                    handleUnauthorized();
                }
            } catch (error) {
                handleUnauthorized();
            }
        };

        const handleUnauthorized = () => {
            setLoggedIn(false);
            window.location.replace('/signin');
        };

        validateAuth();
    }, [setUser, setUserId, setLoggedIn, setEmail, isSubscribed]);

    // Check if window is smaller than 768px
    const [buttonsDirection, setButtonsDirection] = React.useState('row');

    React.useEffect(() => {
        console.log('window.innerWidth:', window.innerWidth);
        if (window.innerWidth < 768) {
            setButtonsDirection('col');
        }
        else {
            setButtonsDirection('row');
        }
    }, [window.innerWidth]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/sources/' + currentChatId)
        .then(response => {
            setSources(response.data);
        })
        .catch(error => {
        });
    }, [openSources, currentChatId, messageIsLoading]);

    const [currentChatName, setCurrentChatName] = React.useState('');

    // Effect to handle chat_id from URL
    React.useEffect(() => {
        if (chatId && !isValidatingChat) {
            setCurrentChatId(chatId);
            // Fetch chat data based on ID
            axios.get(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}`)
                .then(response => {
                    if (response.data.length > 0) {
                        setCurrentChatName(response.data[0].conversation_name);
                    }
                })
                .catch(error => {
                    console.error('Error fetching chat:', error);
                });
        }
    }, [chatId, isValidatingChat]);

    if (isValidatingChat) {
        return (
            <div className="flex h-screen items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
            </div>
        );
    }

    if (showSubscribe) {
        return (
            <Subscribe />
        )
    }

    return (
        <UserContext.Provider value={{ messages, setMessages, chats, setChats, messageIsLoading, setMessageIsLoading, messageIsLoadingDetails, setMessageIsLoadingDetails, currentChatId, setCurrentChatId, currentChatName, setCurrentChatName, sources, setSources, openSources, setOpenSources, newSourcesNotification, setNewSourcesNotification, model, setModel, isOpen, setIsOpen, enableChatInput, setEnableChatInput, sourcesDrawerOpen, setSourcesDrawerOpen, messageIdForSources, setMessageIdForSources, sparkaiSidebarOpen, setSparkaiSidebarOpen, marginLeft, setMarginLeft, showNewsModal, setShowNewsModal, hasUnreadNews, setHasUnreadNews, chatInput, setChatInput }}>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <SparkaiSidebar setSidebarOpen={setSparkaiSidebarOpen} sidebarOpen={sparkaiSidebarOpen} />
                
                {/* Main Content */}
                <div 
                    className={`flex-1 transition-all duration-300 ease-in-out`}
                    style={{
                        marginLeft: sparkaiSidebarOpen ? '100px' : '-100px',
                    }}
                >
                    <Helmet>
                        <title>SparkAI Chat</title>
                    </Helmet>
                    <NewsModal
                        isOpen={showNewsModal}
                        onClose={() => {
                            setShowNewsModal(false);
                            setHasUnreadNews(false);
                        }}
                    />
                    <ToastContainer />
                    <ChatContent />
                </div>
            </div>
        </UserContext.Provider>
    )
}