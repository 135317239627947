import React from "react";
import { Container, Grid, Box, Avatar, Typography, Button, Card, CardContent, Menu, MenuItem, Stack } from "@mui/joy";
import { AccountCircle, CalendarToday, Language, Email, Public, Money, Lock } from "@mui/icons-material";
import { MainUserContext } from "../App";
import axios from "axios";
import { UserCircleIcon, EnvelopeIcon, BuildingOfficeIcon, ArrowLeftEndOnRectangleIcon, ArrowUturnLeftIcon, PencilSquareIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { motion, AnimatePresence } from "framer-motion";
import { FaBolt, FaKey, FaGlobe } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { checkAuthStatus, fetchUserData } from '../services/authService';

const PersonalInfo = ({ user }) => {
    const { setUser } = React.useContext(MainUserContext);
    const [editName, setEditName] = React.useState(false);
    const [userName, setUserName] = React.useState(user?.name || '');

    const handleChangeUserName = (e) => {
        e.preventDefault();
        setUser({ ...user, name: userName });
        setEditName(false);
        axios.put(process.env.REACT_APP_API_URL + '/api/users/change_name/', { id: user.user_id, name: userName })
            .then((response) => {
                console.log('Name changed:', response.data);
            })
            .catch((error) => {
                console.error('Error changing name:', error);
            });
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-6"
        >
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
                <p className="text-gray-600 text-sm">
                    Manage your personal information, including your name, email and institution.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Name Card */}
                <motion.div 
                    className="relative group overflow-hidden"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex justify-between items-start mb-4">
                            <div className="flex items-center gap-2">
                                <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                    <UserCircleIcon className="w-5 h-5 text-emerald-600" />
                                </div>
                                <h3 className="font-medium text-gray-800">Name</h3>
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => setEditName(!editName)}
                                className="text-emerald-600 hover:text-emerald-700"
                            >
                                {!editName ? (
                                    <PencilSquareIcon className="w-5 h-5" />
                                ) : (
                                    <XCircleIcon className="w-5 h-5 text-red-500" />
                                )}
                            </motion.button>
                        </div>

                        {!editName ? (
                            <p className="text-gray-600">
                                {user?.name || "Not specified"}
                            </p>
                        ) : (
                            <form onSubmit={handleChangeUserName} className="space-y-3">
                                <input
                                    className="w-full px-3 py-2 rounded-lg border border-emerald-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none transition-all duration-200"
                                    type="text"
                                    placeholder="Enter your name"
                                    defaultValue={user?.name}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    type="submit"
                                    className="w-full py-2 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
                                >
                                    Save Changes
                                </motion.button>
                            </form>
                        )}
                    </div>
                </motion.div>

                {/* Email Card */}
                <motion.div 
                    className="relative group overflow-hidden"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <EnvelopeIcon className="w-5 h-5 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Email</h3>
                        </div>
                        <p className="text-gray-600">{user?.mail}</p>
                    </div>
                </motion.div>

                {/* Institution Card */}
                <motion.div 
                    className="relative group overflow-hidden md:col-span-2"
                    whileHover={{ scale: 1.01 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                    <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <BuildingOfficeIcon className="w-5 h-5 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Institution / Company</h3>
                        </div>
                        <p className="text-gray-600">
                            {user?.institution || "Not affiliated"}
                        </p>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}

const PlansBilling = ({ user }) => {
    const [currentPlan, setCurrentPlan] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [billingCycle, setBillingCycle] = React.useState('monthly');
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subscriptions/current`, {
                    withCredentials: true
                });
                setCurrentPlan(response.data);
            } catch (error) {
                console.error('Error fetching subscription:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionDetails();
    }, []);

    const planFeatures = {
        free: {
            name: 'Free',
            price: { monthly: 0, yearly: 0 },
            features: [
                '5 AI conversations per day',
                'Basic SparkAI features',
                'Community support'
            ],
            limits: {
                conversations: 5,
                proMessages: 0,
                apexMessages: 0
            }
        },
        personal: {
            name: 'Personal',
            price: { monthly: 24.95, yearly: 239.40 },
            features: [
                'Unlimited AI conversations',
                '100 Pro messages per month',
                'Focus mode for papers',
                'Priority support'
            ],
            limits: {
                conversations: -1,
                proMessages: 100,
                apexMessages: 0
            }
        },
        expert: {
            name: 'Expert',
            price: { monthly: 64.95, yearly: 623.40 },
            features: [
                'Everything in Personal',
                'Unlimited Pro messages',
                '50 NOVA messages/month',
                '24/7 priority support'
            ],
            limits: {
                conversations: -1,
                proMessages: -1,
                apexMessages: 50
            }
        },
        apex: {
            name: 'NOVA',
            price: { monthly: 99.95, yearly: 959.40 },
            features: [
                'Everything in Expert',
                'Unlimited NOVA messages',
                'Early access features',
                'Dedicated support'
            ],
            limits: {
                conversations: -1,
                proMessages: -1,
                apexMessages: -1
            }
        }
    };

    const handleUpgrade = () => {
        navigate('/subscribe');
    };

    const handleCancelSubscription = async () => {
        if (window.confirm('Are you sure you want to cancel your subscription?')) {
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/subscriptions/cancel`, {}, {
                    withCredentials: true
                });
                // Refresh subscription details
                window.location.reload();
            } catch (error) {
                console.error('Error canceling subscription:', error);
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-emerald-500 border-t-transparent"></div>
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-6"
        >
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold text-gray-800">Plans & Billing</h2>
                <p className="text-gray-600 text-sm">
                    Manage your subscription and billing information.
                </p>
            </div>

            {/* Current Plan Overview */}
            <div className="bg-white rounded-xl border border-emerald-100 p-6 space-y-6">
                <div className="flex items-center justify-between">
                    <div className="space-y-1">
                        <h3 className="text-lg font-medium text-gray-800">
                            Current Plan: {currentPlan?.name || 'Free'}
                        </h3>
                        <p className="text-sm text-gray-500">
                            {currentPlan?.status === 'active' ? (
                                `Renews on ${new Date(currentPlan.current_period_end).toLocaleDateString()}`
                            ) : 'No active subscription'}
                        </p>
                    </div>
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleUpgrade}
                        className="px-4 py-2 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
                    >
                        {currentPlan?.status === 'active' ? 'Change Plan' : 'Upgrade'}
                    </motion.button>
                </div>

                {/* Usage Stats */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">AI Conversations</div>
                        <div className="text-lg font-medium text-gray-800">
                            {currentPlan?.usage?.conversations || 0} / 
                            {planFeatures[currentPlan?.plan_id || 'free'].limits.conversations === -1 
                                ? '∞' 
                                : planFeatures[currentPlan?.plan_id || 'free'].limits.conversations}
                        </div>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Pro Messages</div>
                        <div className="text-lg font-medium text-gray-800">
                            {currentPlan?.usage?.proMessages || 0} / 
                            {planFeatures[currentPlan?.plan_id || 'free'].limits.proMessages === -1 
                                ? '∞' 
                                : planFeatures[currentPlan?.plan_id || 'free'].limits.proMessages}
                        </div>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">NOVA Messages</div>
                        <div className="text-lg font-medium text-gray-800">
                            {currentPlan?.usage?.apexMessages || 0} / 
                            {planFeatures[currentPlan?.plan_id || 'free'].limits.apexMessages === -1 
                                ? '∞' 
                                : planFeatures[currentPlan?.plan_id || 'free'].limits.apexMessages}
                        </div>
                    </div>
                </div>

                {/* Billing History */}
                {currentPlan?.status === 'active' && (
                    <div className="space-y-4">
                        <h4 className="font-medium text-gray-800">Billing History</h4>
                        <div className="border rounded-lg overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Invoice</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {currentPlan?.invoices?.map((invoice) => (
                                        <tr key={invoice.id}>
                                            <td className="px-6 py-4 text-sm text-gray-500">
                                                {new Date(invoice.date).toLocaleDateString()}
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-500">
                                                €{(invoice.amount / 100).toFixed(2)}
                                            </td>
                                            <td className="px-6 py-4">
                                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                                    invoice.status === 'paid' 
                                                        ? 'bg-green-100 text-green-800' 
                                                        : 'bg-yellow-100 text-yellow-800'
                                                }`}>
                                                    {invoice.status}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 text-sm">
                                                <a 
                                                    href={invoice.invoice_url} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                    className="text-emerald-600 hover:text-emerald-700"
                                                >
                                                    Download
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Cancel Subscription */}
                {currentPlan?.status === 'active' && (
                    <div className="pt-4 border-t">
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={handleCancelSubscription}
                            className="text-red-600 hover:text-red-700 text-sm font-medium"
                        >
                            Cancel subscription
                        </motion.button>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

const Security = ({ user }) => {
    const { setUser } = React.useContext(MainUserContext);
    const [editPassword, setEditPassword] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passwordMessage, setPasswordMessage] = React.useState('');
    const [oldPasswordMessage, setOldPasswordMessage] = React.useState('');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setPasswordMessage('');
        setOldPasswordMessage('');
        setIsSuccess(false);
        setIsLoading(true);
        
        if (password !== confirmPassword) {
            setPasswordMessage("Passwords don't match");
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/api/users/change_password/', {
                id: user.user_id,
                old_password: oldPassword,
                new_password: password
            });

            if (response.data.status === 'success') {
                setIsSuccess(true);
                setPasswordMessage('Password updated successfully!');
                
                // Animate success state
                setTimeout(() => {
                    setEditPassword(false);
                    setOldPassword('');
                    setPassword('');
                    setConfirmPassword('');
                    setPasswordMessage('');
                    setIsSuccess(false);
                }, 2000);
            } else {
                setOldPasswordMessage(response.data.old_password_message);
            }
        } catch (error) {
            if (error.response?.status === 401) {
                setOldPasswordMessage('Current password is incorrect');
            } else {
                setPasswordMessage('Error changing password');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="space-y-6"
        >
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold text-gray-800">Security Settings</h2>
                <p className="text-gray-600 text-sm">
                    Manage your password and security preferences.
                </p>
            </div>

            <div className="relative group overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-xl transition-all duration-300" />
                <div className="relative bg-white rounded-xl border border-emerald-100 p-4 shadow-sm">
                    <div className="flex justify-between items-start mb-4">
                        <div className="flex items-center gap-2">
                            <div className="p-2 bg-gradient-to-r from-emerald-50 to-green-50 rounded-lg">
                                <FaKey className="w-4 h-4 text-emerald-600" />
                            </div>
                            <h3 className="font-medium text-gray-800">Password</h3>
                        </div>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => setEditPassword(!editPassword)}
                            className="text-emerald-600 hover:text-emerald-700"
                        >
                            {!editPassword ? (
                                <PencilSquareIcon className="w-5 h-5" />
                            ) : (
                                <XCircleIcon className="w-5 h-5 text-red-500" />
                            )}
                        </motion.button>
                    </div>

                    {!editPassword ? (
                        <div className="flex items-center gap-2">
                            <p className="text-gray-600">••••••••</p>
                            {isSuccess && (
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.95 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.95 }}
                                    className="flex items-center gap-2 px-3 py-1.5 bg-emerald-50 text-emerald-600 rounded-full"
                                >
                                    <CheckCircleIcon className="w-5 h-5" />
                                    <span className="text-sm font-medium">Password updated successfully!</span>
                                </motion.div>
                            )}
                        </div>
                    ) : (
                        <form onSubmit={handleChangePassword} className="space-y-4">
                            <div>
                                <input
                                    type="password"
                                    placeholder="Current password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    className={`w-full px-3 py-2 rounded-lg border ${
                                        oldPasswordMessage 
                                            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                                            : 'border-emerald-200 focus:border-emerald-500 focus:ring-emerald-500'
                                    } outline-none transition-all duration-200`}
                                />
                                {oldPasswordMessage && (
                                    <motion.p 
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="mt-1 text-sm text-red-500 flex items-center gap-1"
                                    >
                                        <XCircleIcon className="w-4 h-4" />
                                        {oldPasswordMessage}
                                    </motion.p>
                                )}
                            </div>
                            <div>
                                <input
                                    type="password"
                                    placeholder="New password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-emerald-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none transition-all duration-200"
                                />
                            </div>
                            <div>
                                <input
                                    type="password"
                                    placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className={`w-full px-3 py-2 rounded-lg border ${
                                        passwordMessage 
                                            ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                                            : 'border-emerald-200 focus:border-emerald-500 focus:ring-emerald-500'
                                    } outline-none transition-all duration-200`}
                                />
                                {passwordMessage && (
                                    <motion.p 
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className={`mt-1 text-sm flex items-center gap-1 ${
                                            isSuccess ? 'text-emerald-500' : 'text-red-500'
                                        }`}
                                    >
                                        {isSuccess ? (
                                            <CheckCircleIcon className="w-4 h-4" />
                                        ) : (
                                            <XCircleIcon className="w-4 h-4" />
                                        )}
                                        {passwordMessage}
                                    </motion.p>
                                )}
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                type="submit"
                                disabled={isLoading}
                                className={`w-full py-2 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 relative ${
                                    isLoading ? 'opacity-90 cursor-not-allowed' : ''
                                }`}
                            >
                                {isLoading ? (
                                    <motion.div
                                        animate={{ rotate: 360 }}
                                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                        className="w-5 h-5 border-2 border-white border-t-transparent rounded-full mx-auto"
                                    />
                                ) : (
                                    "Update Password"
                                )}
                            </motion.button>
                        </form>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

const Account = () => {
    const { user, setUser, userId, setUserId, loggedIn, setLoggedIn, email, setEmail } = React.useContext(MainUserContext);
    const [activeTab, setActiveTab] = React.useState('personal');
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();

    React.useEffect(() => {
        const validateAuth = async () => {
            setIsLoading(true);
            try {
                const user = await checkAuthStatus();
                if (user) {
                    const userData = await fetchUserData(user.mail);
                    setUser(userData);
                    setUserId(userData.user_id);
                    setLoggedIn(true);
                    setEmail(userData.mail);
                } else {
                    handleUnauthorized();
                }
            } catch (error) {
                handleUnauthorized();
            } finally {
                setIsLoading(false);
            }
        };

        validateAuth();
    }, [setUser, setUserId, setLoggedIn, setEmail]);

    const handleUnauthorized = () => {
        setLoggedIn(false);
        window.location.replace('/signin');
    };

    const logOut = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/logout', {withCredentials: true})
            .then(response => {
                window.open('https://ederspark.com/?page=platform', '_self');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
        setLoggedIn(false);
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
            <Helmet>
                <title>EderSpark Account</title>
            </Helmet>

            {/* Header */}
            <div className="bg-white border-b border-gray-100">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <h1 className="text-xl font-semibold text-gray-800">
                            Account Settings
                        </h1>
                        <div className="flex items-center gap-3">
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => navigate('/chat')}
                                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200 transition-all duration-200"
                            >
                                <ArrowUturnLeftIcon className="w-4 h-4" />
                                <span>Back to Chat</span>
                            </motion.button>
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={logOut}
                                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-red-50 text-red-600 hover:bg-red-100 transition-all duration-200"
                            >
                                <ArrowLeftEndOnRectangleIcon className="w-4 h-4" />
                                <span>Log out</span>
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex gap-8">
                    {/* Sidebar */}
                    <div className="w-64 flex-shrink-0">
                        <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm">
                            {/* User Profile */}
                            <div className="flex flex-col items-center pb-4 border-b border-gray-100">
                                <div className="w-20 h-20 rounded-full bg-gradient-to-r from-emerald-500 to-green-500 flex items-center justify-center text-white text-2xl font-semibold mb-3">
                                    {user?.name ? user.name.charAt(0).toUpperCase() : 'U'}
                                </div>
                                <h2 className="text-lg font-medium text-gray-800">
                                    {user?.name || "Not specified"}
                                </h2>
                                <p className="text-sm text-gray-500">{user?.mail}</p>
                            </div>

                            {/* Navigation */}
                            <nav className="mt-4 space-y-1">
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setActiveTab('personal')}
                                    className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                        activeTab === 'personal' 
                                            ? 'bg-emerald-50 text-emerald-600' 
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <UserCircleIcon className="w-5 h-5" />
                                    Personal Info
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setActiveTab('billing')}
                                    className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                        activeTab === 'billing' 
                                            ? 'bg-emerald-50 text-emerald-600' 
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <FaBolt className="w-4 h-4" />
                                    Plans & Billing
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setActiveTab('security')}
                                    className={`w-full flex items-center gap-2 px-3 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                        activeTab === 'security' 
                                            ? 'bg-emerald-50 text-emerald-600' 
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <FaKey className="w-4 h-4" />
                                    Security
                                </motion.button>
                            </nav>
                        </div>
                    </div>

                    {/* Main Content Area */}
                    <div className="flex-1">
                        <div className="bg-white rounded-xl border border-gray-200 p-6 shadow-sm">
                            <AnimatePresence mode="wait">
                                {activeTab === 'personal' && <PersonalInfo user={user} />}
                                {activeTab === 'billing' && <PlansBilling user={user} />}
                                {activeTab === 'security' && <Security user={user} />}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
