import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import { ReactComponent as Logo } from '../assets/logo-no-slogan-no-background.svg';
import { RiLockPasswordFill } from "react-icons/ri";
import { IoShieldCheckmark } from "react-icons/io5";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [requestSuccess, setRequestSuccess] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match.');
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, {
            token: token,
            password: password,
        })
        .then(response => {
            setRequestSuccess('Your password has been reset successfully.');
            setError('');
            setTimeout(() => {
                navigate('/login');
            }, 3000); // Redirect to login page after 3 seconds
        })
        .catch(error => {
            console.error('Error resetting password:', error);
            setError('An error occurred. Please try again.');
        });
    }

    return (
        <motion.main 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen flex items-center justify-center bg-gradient-to-br from-emerald-50/40 via-white to-green-50/40"
        >
            <Helmet>
                <title>Create New Password - Freiya</title>
            </Helmet>

            <div className="w-full max-w-[1200px] h-[600px] mx-4 flex rounded-2xl overflow-hidden shadow-2xl bg-white">
                {/* Decorative Left Panel */}
                <motion.div 
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-emerald-500 to-green-500 p-12 flex-col items-center justify-center relative overflow-hidden"
                >
                    <div className="relative z-10 text-center">
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className="mb-8"
                        >
                            <RiLockPasswordFill className="w-24 h-24 text-white/90 mx-auto" />
                        </motion.div>
                        <h2 className="text-3xl font-bold text-white mb-4">Create New Password</h2>
                        <p className="text-emerald-50 text-lg max-w-sm mx-auto">
                            Choose a strong password to keep your Freiya account secure
                        </p>
                    </div>

                    {/* Decorative elements */}
                    <div className="absolute inset-0 opacity-10">
                        <div className="absolute top-[10%] right-[20%] w-[200px] h-[200px] rounded-full border-[30px] border-white" />
                        <div className="absolute bottom-[-10%] left-[-5%] w-[300px] h-[300px] rounded-full border-[50px] border-white" />
                    </div>
                </motion.div>

                {/* Right Panel - Form Side */}
                <motion.div 
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    className="w-full lg:w-1/2 p-8 sm:p-12 flex flex-col justify-center relative"
                >
                    {/* Logo positioned absolutely */}
                    <div className="absolute top-[-50px] right-[50px]">
                        <Logo className="w-[200px] h-[200px]" />
                    </div>

                    <div className="w-full max-w-md mx-auto space-y-8 mt-16">
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">Set your new password</h1>
                            <p className="mt-2 text-gray-600">Please enter and confirm your new password below.</p>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="space-y-4">
                                <div>
                                    <Input
                                        sx={{
                                            '--Input-focusedThickness': '2px',
                                            '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.2)',
                                            '--Input-radius': '0.75rem',
                                            '--Input-gap': '0.75rem',
                                            '--Input-placeholderOpacity': 0.5,
                                            'backgroundColor': 'rgb(249, 250, 251)',
                                            '&:hover': {
                                                '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.28)',
                                                'backgroundColor': 'rgb(243, 244, 246)',
                                            },
                                        }}
                                        className="hover:shadow-sm transition-all duration-200"
                                        startDecorator={<RiLockPasswordFill className="text-emerald-500 text-xl" />}
                                        size="lg"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your new password"
                                        required
                                    />
                                </div>

                                <div>
                                    <Input
                                        sx={{
                                            '--Input-focusedThickness': '2px',
                                            '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.2)',
                                            '--Input-radius': '0.75rem',
                                            '--Input-gap': '0.75rem',
                                            '--Input-placeholderOpacity': 0.5,
                                            'backgroundColor': 'rgb(249, 250, 251)',
                                            '&:hover': {
                                                '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.28)',
                                                'backgroundColor': 'rgb(243, 244, 246)',
                                            },
                                        }}
                                        className="hover:shadow-sm transition-all duration-200"
                                        startDecorator={<RiLockPasswordFill className="text-emerald-500 text-xl" />}
                                        size="lg"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm your new password"
                                        required
                                    />
                                </div>

                                {passwordError && (
                                    <motion.p 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="text-sm text-red-500 flex items-center gap-1"
                                    >
                                        <span className="inline-block w-1 h-1 rounded-full bg-red-500 mr-1" />
                                        {passwordError}
                                    </motion.p>
                                )}
                                {error && (
                                    <motion.p 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="text-sm text-red-500 flex items-center gap-1"
                                    >
                                        <span className="inline-block w-1 h-1 rounded-full bg-red-500 mr-1" />
                                        {error}
                                    </motion.p>
                                )}
                                {requestSuccess && (
                                    <motion.div 
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="p-4 bg-emerald-50 rounded-xl border border-emerald-100"
                                    >
                                        <div className="flex items-center gap-2 text-emerald-600">
                                            <IoShieldCheckmark className="w-5 h-5 flex-shrink-0" />
                                            <p className="text-sm font-medium">{requestSuccess}</p>
                                        </div>
                                    </motion.div>
                                )}
                            </div>

                            <div className="space-y-4">
                                <motion.button
                                    whileHover={{ scale: 1.01 }}
                                    whileTap={{ scale: 0.99 }}
                                    type="submit"
                                    className="w-full py-3 px-4 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-xl font-medium shadow-lg shadow-emerald-100/50 hover:shadow-xl hover:shadow-emerald-100/50 transition-all duration-200"
                                >
                                    Reset Password
                                </motion.button>

                                <div className="text-center">
                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        type="button"
                                        onClick={() => navigate('/signin')}
                                        className="text-sm text-emerald-600 hover:text-emerald-500 font-medium"
                                    >
                                        ← Back to Sign In
                                    </motion.button>
                                </div>
                            </div>
                        </form>
                    </div>
                </motion.div>
            </div>
        </motion.main>
    );
}

const styles = {
    main_container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      marginTop: '-3rem',
    },
    logo: {
      width: '15rem',
      height: '15rem',
      margin: '0',
      marginBottom: '-5rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '22rem',
    },
    inputGroup: {
      marginBottom: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
};
