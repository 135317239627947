import { Stack, Typography } from '@mui/joy';
import '../index.css';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaEye, FaLock, FaShare } from "react-icons/fa6";
import { BiSolidErrorAlt } from "react-icons/bi";
import { IoShieldCheckmark } from "react-icons/io5";
import { Tooltip } from '@mui/joy';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'

import SparkAILogo from '../assets/sparkai_new_only_logo.png';
import EderSparkLogo from '../assets/EderSparkLogoLarge.png';
import ResponseRenderer from './ResponseRenderer';
import { CustomMarkdownRenderer } from './ResponseRenderer';
import { checkAuthStatus } from '../services/authService';

function SharedChatMessage({ role_id, content, messageId, reasoning }) {
    const displayRole = role_id === 1 ? '' : 'Freiya';
    const roleClass = role_id === 1 ? 'message-user' : 'message-bot';
    const containerClass = role_id === 1 ? 'message-container-user' : 'message-container-bot';
    const [citations, setCitations] = React.useState(null);
    const [loadingCitations, setLoadingCitations] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [height, setHeight] = React.useState(0);
    const contentRef = React.useRef(null);

    // Fetch citations from the api
    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/citations/${messageId}`)
                .then(response => {
                    console.log("Citations response:", response.data);
                    setCitations(response.data);
                    setLoadingCitations(false);
                })
                .catch(error => {
                    console.error('Error fetching citations:', error);
                    setLoadingCitations(false);
                });
        }
    }, [messageId]);

    const [markdownReasoning, setMarkdownReasoning] = React.useState(null);

    React.useEffect(() => {
        if (reasoning) {
            CustomMarkdownRenderer({ text: reasoning }).then(setMarkdownReasoning);
        }
    }, [reasoning]);


    React.useEffect(() => {
        console.log("Citations:", citations);
    }, [citations]);

    React.useEffect(() => {
        console.log("Loading citations:", loadingCitations);
    }, [loadingCitations]);

    const toggleAccordion = () => {
        if (isOpen) {
            setHeight(0);
            setTimeout(() => setIsOpen(false), 300);
        } else {
            setIsOpen(true);
            setHeight(contentRef.current?.scrollHeight || 0);
        }
    };

    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`${containerClass} relative group`}
        >
            <div className={`absolute ${role_id === 2 ? 'left-[-60px] top-10' : 'right-[-60px] top-3'}`}>
                {role_id === 2 ? (
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        className="flex items-center justify-center w-10 h-10 rounded-xl bg-gradient-to-br from-emerald-50 to-green-50 border border-emerald-100"
                    >
                        <img className="w-6 h-6" src={SparkAILogo} alt="Freiya" />
                    </motion.div>
                ) : (
                    <div className="w-10 h-10 rounded-xl bg-gray-50 border border-gray-100 flex items-center justify-center">
                        <FaLock className="w-4 h-4 text-gray-400" />
                    </div>
                )}
            </div>
            <div className={`${roleClass} w-full relative flex flex-col gap-3`}>
                {role_id === 2 && reasoning && (
                    <div className="hover:bg-orange-50/50 rounded-2xl p-2 transition-all duration-300">
                        <button 
                            onClick={toggleAccordion}
                            className="w-full flex justify-between items-center px-4 py-2 rounded-xl hover:bg-orange-50/50"
                        >
                            <div className="flex items-center gap-3">
                                <span className="px-2 py-1 rounded-md font-mono text-xs tracking-wider text-orange-600 bg-orange-100">
                                    NOVA
                                </span>
                                <span className="font-medium text-sm text-orange-600">
                                    Reasoning Process
                                </span>
                                <span className="text-xs px-2 py-0.5 rounded-md bg-orange-50 text-orange-500">
                                    {isOpen ? 'Click to collapse' : 'Click to expand'}
                                </span>
                            </div>
                            <motion.div
                                animate={{ rotate: isOpen ? 180 : 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-orange-500">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                            </motion.div>
                        </button>

                        <motion.div 
                            animate={{ height: isOpen ? 'auto' : 0 }}
                            transition={{ duration: 0.3 }}
                            className="overflow-hidden"
                        >
                            <div className="px-4 py-3 my-2 border-l-2 border-orange-200 pl-4">
                                <ResponseRenderer 
                                    response={markdownReasoning} 
                                    citations={citations || {}}
                                    showFocus={false}
                                    textSize="text-sm"
                                    textColor="gray-600"
                                    showCitations={false}
                                    allowActions={false}
                                />
                            </div>
                        </motion.div>
                    </div>
                )}
                <div className="w-full">
                    {role_id === 2 ? (
                        <ResponseRenderer 
                            response={content} 
                            citations={citations || {}}
                            showFocus={false}
                            allowActions={false}
                        />
                    ) : (
                        <div className="text-gray-700 text-[0.95rem]">
                            <Markdown
                                remarkPlugins={[remarkGfm, remarkMath]}
                                rehypePlugins={[rehypeKatex, rehypeRaw]}
                            >
                                {content}
                            </Markdown>
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    );
}

function SharedMessagesList({ messages, chatName, views, createdAt }) {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    React.useEffect(() => {
        const validateAuth = async () => {
            try {
                const user = await checkAuthStatus();
                setIsLoggedIn(!!user);
            } catch (error) {
                setIsLoggedIn(false);
            }
        };
        validateAuth();
    }, []);

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex flex-col w-full min-h-screen"
        >
            <div className='flex flex-col items-center justify-start absolute top-0 left-0 space-y-4 p-4 bg-white'>
                <img src={EderSparkLogo} alt="EderSpark Logo" className='w-[120px] md:w-[175px]' />
                {isLoggedIn ? (
                    <button 
                        className='w-full bg-gradient-to-r from-emerald-500 to-green-500 text-white px-4 py-2 rounded-lg 
                        hover:from-emerald-600 hover:to-green-600 transition-all duration-300 shadow-md 
                        hover:shadow-lg font-medium text-sm md:text-base flex items-center justify-center gap-2'
                        onClick={() => window.location.href = '/freiya'}
                    >
                        Return to Freiya
                    </button>
                ) : (
                    <div className="flex flex-col gap-3 w-full">
                        <button 
                            className='w-full bg-gradient-to-r from-emerald-500 to-green-500 text-white px-4 py-2 rounded-lg 
                            hover:from-emerald-600 hover:to-green-600 transition-all duration-300 shadow-md 
                            hover:shadow-lg font-medium text-sm md:text-base flex items-center justify-center gap-2'
                            onClick={() => window.location.href = '/freiya'}
                        >
                            Try our chat
                        </button>
                        <button 
                            className='w-full border-2 border-emerald-500 text-emerald-600 px-4 py-2 rounded-lg 
                            hover:bg-emerald-50 transition-all duration-300 shadow-sm hover:shadow-md 
                            font-medium text-sm md:text-base flex items-center justify-center gap-2'
                            onClick={() => window.location.href = '/signin'}
                        >
                            Sign in
                        </button>
                    </div>
                )}
            </div>
            <div className="flex flex-col items-center w-full px-4 py-8">
                <motion.div 
                    initial={{ y: -20 }}
                    animate={{ y: 0 }}
                    className="w-full max-w-3xl bg-white rounded-2xl p-6 mb-8"
                >
                    <div className="flex flex-col items-center gap-4">
                        <div className="flex items-center justify-center w-12 h-12 rounded-2xl bg-gradient-to-br from-emerald-50 to-green-50 border border-emerald-100">
                            <img src={SparkAILogo} alt="Freiya" className="w-8 h-8" />
                        </div>
                        
                        <div className="text-center">
                            <h1 className="text-2xl font-semibold bg-gradient-to-r from-emerald-600 to-green-600 bg-clip-text text-transparent">
                                {chatName}
                            </h1>
                            <p className="text-sm text-gray-500 mt-1">
                                Shared conversation
                            </p>
                        </div>

                        <div className="flex items-center gap-6 mt-2">
                            <Tooltip title="View count" placement="top" arrow>
                                <div className="flex items-center gap-2 text-gray-500">
                                    <FaEye className="w-4 h-4" />
                                    <span className="text-sm font-medium">{views}</span>
                                </div>
                            </Tooltip>

                            <Tooltip title="Verified content" placement="top" arrow>
                                <div className="flex items-center gap-2 text-emerald-500">
                                    <IoShieldCheckmark className="w-4 h-4" />
                                    <span className="text-sm font-medium">Verified</span>
                                </div>
                            </Tooltip>

                            {/* Add share button or other actions here */}
                        </div>
                    </div>
                </motion.div>

                <div className="w-full max-w-3xl space-y-8 mb-20">
                    {messages.map((chat, index) => (
                        <SharedChatMessage 
                            key={index} 
                            role_id={chat.role_id} 
                            content={chat.content} 
                            messageId={chat.message_id}
                            reasoning={chat.reasoning}
                        />
                    ))}
                </div>
            </div>
        </motion.div>
    );
}

export default function SharedChatView() {
    const { shareId } = useParams();
    const [messages, setMessages] = React.useState([]);
    const [chatName, setChatName] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [views, setViews] = React.useState(0);
    const hasInitialized = React.useRef(false);

    React.useEffect(() => {
        const viewKey = `shared-chat-view-${shareId}`;
        const lastViewTime = parseInt(localStorage.getItem(viewKey));
        console.log("Last view time:", lastViewTime);
        const currentTime = new Date().getTime();
        const COOLDOWN_PERIOD = 12 * 60 * 60 * 1000; // 24 hours in milliseconds

        const initializeViews = async () => {
            try {
                // First get current view count
                const viewResponse = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/shared-chats/${shareId}/get-views`
                );
                setViews(viewResponse.data.views);

                // Check if enough time has passed since last view
                const canAddView = !lastViewTime || isNaN(lastViewTime) || (currentTime - lastViewTime) > COOLDOWN_PERIOD;
                console.log("Can add view:", canAddView, "Time difference:", currentTime - lastViewTime);

                if (canAddView) {
                    // Store current timestamp
                    localStorage.setItem(viewKey, currentTime.toString());
                    console.log("View added");
                    const addResponse = await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/shared-chats/${shareId}/add-view`,
                        {
                            timestamp: currentTime,
                            shareId: shareId
                        }
                    );
                    
                    if (addResponse.data.success) {
                        setViews(prev => prev + 1);
                    }
                }
            } catch (error) {
                console.error('Error handling views:', error);
            }
        };

        if (!hasInitialized.current) {
            hasInitialized.current = true;
            initializeViews();
        }
    }, [shareId]);

    React.useEffect(() => {
        const fetchSharedChat = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shared-chats/${shareId}`);
                if (response.data.status === 'success') {
                    console.log("Response data:", response.data);
                    setMessages(response.data.share.messages);
                    setChatName(response.data.share.conversation_name);
                } else {
                    setError('Failed to load shared chat');
                }
            } catch (err) {
                setError('This shared chat may have expired or does not exist');
                console.error('Error fetching shared chat:', err);
            } finally {
                setLoading(false);
            }
        };
        console.log("Fetching shared chat for:", shareId);
        if (shareId) {
            fetchSharedChat();
        }
    }, [shareId]);

    if (loading) {
        return (
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex justify-center items-center h-screen"
            >
                <div className="flex flex-col items-center gap-4">
                    <motion.img 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            rotate: [0, 360]
                        }}
                        transition={{ 
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                        src={SparkAILogo} 
                        alt="Freiya Logo" 
                        className="w-12 h-12"
                    />
                    <p className="text-gray-600 font-medium">Loading shared chat...</p>
                </div>
            </motion.div>
        );
    }

    if (error) {
        return (
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex justify-center items-center h-screen"
            >
                <div className="flex flex-col items-center gap-4 p-8 rounded-xl bg-red-50 border border-red-100">
                    <motion.div
                        animate={{ rotate: [0, 10, -10, 0] }}
                        transition={{ duration: 0.5 }}
                    >
                        <BiSolidErrorAlt className="w-12 h-12 text-red-500" />
                    </motion.div>
                    <p className="text-red-600 font-medium text-center">{error}</p>
                </div>
            </motion.div>
        );
    }

    return <SharedMessagesList messages={messages} chatName={chatName} views={views} />;
} 