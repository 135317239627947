import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import { ReactComponent as Logo} from '../assets/logo-no-slogan-no-background.svg';
import { FaUser } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoShieldCheckmark } from "react-icons/io5";
import Checkbox from '@mui/joy/Checkbox';
import { Link } from "react-router-dom"
import Card from '@mui/joy/Card';
import axios from 'axios';
import { useEffect } from 'react';
import { MainUserContext } from '../App';

import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';
import { checkAuthStatus, fetchUserData } from '../services/authService';
import { motion } from 'framer-motion';


export default function Signin() {

  const { userId, setUserId, email, setEmail, loggedIn, setLoggedIn, setUser } = React.useContext(MainUserContext);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const navigate = useNavigate();

  React.useEffect(() => {
    const validateAuth = async () => {
      try {
        const user = await checkAuthStatus();
        
        if (user) {
          const userData = await fetchUserData(user.mail);
          setUser(userData);
          setUserId(userData.user_id);
          setLoggedIn(true);
          setEmail(userData.mail);
          window.location.replace('/chat');
        } else {
          setLoggedIn(false);
        }
      } catch (error) {
        setLoggedIn(false);
      }
    };

    validateAuth();
  }, [setUser, setUserId, setLoggedIn, setEmail]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    // You can add authentication logic here
    axios.post(process.env.REACT_APP_API_URL + '/api/auth/verify', {
      mail: email,
      password: password,
    }, {withCredentials: true}).then(response => {
      console.log('Response:', response.data);
      if (response.data.auth) {
        navigate('/');
      } else {
        setPasswordError('Invalid email or password');
      }
    }).catch(error => {
      console.error(error);
      setEmailError('An error occurred');
      return;
    });
  }


  return (
    <motion.main 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex items-center justify-center bg-gradient-to-br from-emerald-50/40 via-white to-green-50/40"
    >
      <Helmet>
        <title>Sign in to Freiya</title>
      </Helmet>

      <div className="w-full max-w-[1200px] h-[600px] mx-4 flex rounded-2xl overflow-hidden shadow-2xl bg-white">
        {/* Left Panel - Decorative */}
        <motion.div 
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-emerald-500 to-green-500 p-12 flex-col justify-between relative overflow-hidden"
        >
          <div className="relative z-10">
            <div className="mt-8 space-y-4">
              <h2 className="text-3xl font-bold text-white">Welcome back to Freiya</h2>
              <p className="text-emerald-50 text-lg">Your AI research assistant for enhanced productivity</p>
            </div>
          </div>
          
          {/* Decorative circles */}
          <div className="absolute inset-0 opacity-10">
            <div className="absolute top-[20%] left-[20%] w-[300px] h-[300px] rounded-full border-[40px] border-white" />
            <div className="absolute bottom-[-20%] right-[-10%] w-[400px] h-[400px] rounded-full border-[60px] border-white" />
          </div>
        </motion.div>

        {/* Right Panel - Sign In Form */}
        <motion.div 
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="w-full lg:w-1/2 p-8 sm:p-12 flex flex-col justify-center relative"
        >
          {/* Logo positioned absolutely at the top right */}
          <div className="absolute top-[-50px] right-[50px]">
            <Logo className="w-[200px] h-[200px]" />
          </div>

          <div className="w-full max-w-md mx-auto space-y-8">
            <div className="text-center lg:text-left">
              <h1 className="text-2xl font-semibold text-gray-900">Sign in to your account</h1>
              <p className="mt-2 text-gray-600">Continue your journey with Freiya</p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <Input
                    sx={{
                      '--Input-focusedThickness': '2px',
                      '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.2)',
                      '--Input-radius': '0.75rem',
                      '--Input-gap': '0.75rem',
                      '--Input-placeholderOpacity': 0.5,
                      'backgroundColor': 'rgb(249, 250, 251)',
                      '&:hover': {
                        '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.28)',
                        'backgroundColor': 'rgb(243, 244, 246)',
                      },
                    }}
                    className="hover:shadow-sm transition-all duration-200"
                    startDecorator={<FaUser className="text-emerald-500" />}
                    size="lg"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                    error={!!emailError}
                    required
                  />
                  {emailError && (
                    <motion.p 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="mt-2 text-sm text-red-500 flex items-center gap-1"
                    >
                      <span className="inline-block w-1 h-1 rounded-full bg-red-500 mr-1" />
                      {emailError}
                    </motion.p>
                  )}
                </div>

                <div>
                  <Input
                    sx={{
                      '--Input-focusedThickness': '2px',
                      '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.2)',
                      '--Input-radius': '0.75rem',
                      '--Input-gap': '0.75rem',
                      '--Input-placeholderOpacity': 0.5,
                      'backgroundColor': 'rgb(249, 250, 251)',
                      '&:hover': {
                        '--Input-focusedHighlight': 'rgba(16, 185, 129, 0.28)',
                        'backgroundColor': 'rgb(243, 244, 246)',
                      },
                    }}
                    className="hover:shadow-sm transition-all duration-200"
                    startDecorator={<RiLockPasswordFill className="text-emerald-500" />}
                    size="lg"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    error={!!passwordError}
                    required
                  />
                  {passwordError && (
                    <motion.p 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="mt-2 text-sm text-red-500 flex items-center gap-1"
                    >
                      <span className="inline-block w-1 h-1 rounded-full bg-red-500 mr-1" />
                      {passwordError}
                    </motion.p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center gap-2 text-sm text-gray-600">
                  <Checkbox 
                    checked={showPassword}
                    onChange={handleShowPassword}
                    sx={{
                      '--Checkbox-gap': '0.5rem',
                      '--Checkbox-size': '1rem',
                      'color': 'rgb(16, 185, 129)',
                      '&.Mui-checked': { 
                        color: 'rgb(16, 185, 129)',
                        backgroundColor: 'rgb(209, 250, 229)'
                      },
                    }}
                  />
                  <span>Show password</span>
                </label>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  onClick={() => navigate('/forgot-password')}
                  className="text-sm text-emerald-600 hover:text-emerald-500 font-medium"
                >
                  Forgot password?
                </motion.button>
              </div>

              <div className="space-y-4">
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  type="submit"
                  className="w-full py-3 px-4 bg-gradient-to-r from-emerald-500 to-green-500 text-white rounded-xl font-medium shadow-lg shadow-emerald-100/50 hover:shadow-xl hover:shadow-emerald-100/50 transition-all duration-200"
                >
                  Sign in
                </motion.button>

                {/* Security badge */}
                <div className="flex items-center justify-center gap-2 text-emerald-600">
                  <IoShieldCheckmark className="w-5 h-5" />
                  <span className="text-sm font-medium">All data is encrypted and stored securely</span>
                </div>

                <div className="text-center">
                  <span className="text-sm text-gray-600">New to Freiya? </span>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="button"
                    onClick={() => navigate('/signup')}
                    className="text-sm text-emerald-600 hover:text-emerald-500 font-medium"
                  >
                    Create an account →
                  </motion.button>
                </div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </motion.main>
  );
}
