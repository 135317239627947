import * as React from 'react';
import TopHeader from "../components_focus/TopHeader";
import axios from "axios";
import FocusSidebar from "../components_focus/FocusSidebar";

import { useEffect } from "react";
import { useParams } from 'react-router-dom';

import { useNavigate } from "react-router-dom";
import { MainUserContext } from "../App";

import { Helmet } from 'react-helmet';
import NoPaper from "../components_focus/NoPaper";
import PDFViewer from "../components_focus/PDFViewer";
import FloatingChatBot from "../components_focus/FloatingChatBot";
import RightSideMenu from "../common_components/RightSideMenu";
import { checkAuthStatus, fetchUserData } from '../services/authService';
import FocusContent from "../components_focus/FocusContent";
import { useSearchParams } from 'react-router-dom';
import Subscribe from "./Subscribe";

export const UserContext = React.createContext(null);

export default function Focus() {

    const { loggedIn } = React.useContext(MainUserContext);
    const [searchParams] = useSearchParams();
    const chatId = searchParams.get('chat_id');
    
    const [paper, setPaper] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(true);
    const [enableChatInput, setEnableChatInput] = React.useState(false);
    const [currentChatId, setCurrentChatId] = React.useState(null);
    const { userId } = React.useContext(MainUserContext);

    const [messageIsLoading, setMessageIsLoading] = React.useState(false);
    const [dots, setDots] = React.useState('');
    const [messageIsLoadingDetails, setMessageIsLoadingDetails] = React.useState("");
    const [model, setModel] = React.useState('default');

    const [messages, setMessages] = React.useState([]);
    const [chats, setChats] = React.useState([]);

    const [openSources, setOpenSources] = React.useState(false);
    const [sources, setSources] = React.useState([]);
    const [isChatbotOpen, setIsChatbotOpen] = React.useState(false);

    const [showSurvey, setShowSurvey] = React.useState(false);
    
    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);
    const [showSubscribe, setShowSubscribe] = React.useState(false);

    const { chat_id } = useParams(); // Access the optional `chat_id` parameter

    React.useEffect(() => {
        const validateAuth = async () => {
            try {
                const user = await checkAuthStatus();
                
                if (user) {
                    const userData = await fetchUserData(user.mail);
                    setUser(userData);
                    setUserId(userData.user_id);
                    setLoggedIn(true);
                    setEmail(userData.mail);
                    console.log('isSubscribed:', userData.subscribed);
                    if (!userData.subscribed) {
                        console.log('isSubscribed is false');
                        setShowSubscribe(true);
                    }
                } else {
                    handleUnauthorized();
                }
            } catch (error) {
                handleUnauthorized();
            }
        };

        const handleUnauthorized = () => {
            setLoggedIn(false);
            window.location.replace('/signin');
        };

        validateAuth();
    }, [setUser, setUserId, setLoggedIn, setEmail, setShowSubscribe]);

    const [isValidatingChat, setIsValidatingChat] = React.useState(true);

    React.useEffect(() => {
        const validateChat = async () => {
            if (chatId && userId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chat/check_conversation/${chatId}&${userId}`);
                    if (!response.data.isOwner) {
                        window.location.replace('/focus');
                    }
                } catch (error) {
                    console.error('Error validating chat ownership:', error);
                    window.location.replace('/focus');
                }
            }
            setIsValidatingChat(false);
        };

        validateChat();
    }, [chatId, userId]);

    React.useEffect(() => {
        if (chatId && !isValidatingChat) {
            setCurrentChatId(chatId);
            // Fetch chat data based on ID
            axios.get(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}`)
                .then(response => {
                    if (response.data.length > 0) {
                        setCurrentChatName(response.data[0].conversation_name);
                    }
                })
                .catch(error => {
                    console.error('Error fetching chat:', error);
                });
        }
    }, [chatId, isValidatingChat]);

    React.useEffect(() => {
        console.log('Checking if chat_id is present');
        console.log(chat_id);
        if (chat_id) {
          // If conv_id is present, do something with it (e.g., fetch conversation data)
          const url = process.env.REACT_APP_API_URL + '/api/focus/check_conversation/' + chat_id + '&' + userId;
          console.log('URL:', url);
          axios.get(url)
            .then(response => {
                console.log('Response from check_conversation:', response.data);
                if (response.data.length === 0) {
                    return;
                }
                setCurrentChatId(chat_id);
            })
            .catch(error => {
                console.error('Error fetching conversation data:', error);
            });
        } else {
          console.error('No chat_id provided');
        }
      }, [chat_id, userId]);

    const navigate = useNavigate();    

    useEffect(() => {
        if (!currentChatId) {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/focus/sources/' + currentChatId)
        .then(response => {
            console.log('Response from sources:', response.data);
            setPaper(response.data[0]);
        })
        .catch(error => {
        });
    }, [currentChatId]);

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    const [marginLeft, setMarginLeft] = React.useState(null);
    React.useEffect(() => {
        if (isOpen) {
            setMarginLeft(300);
        } else {
            if (window.innerWidth < 768) {
                setMarginLeft(100);
            } else {
                setMarginLeft(80);
            }
        }
    }, [window.innerWidth, isOpen]);

    const [currentChatName, setCurrentChatName] = React.useState('');

    if (!loggedIn) {
        return null;
    }

    if (isValidatingChat) {
        return (
            <div className="flex h-screen items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
            </div>
        );
    }

    if (showSubscribe) {
        return <Subscribe />;
    }

    return (
        <UserContext.Provider value={{ 
            messages, setMessages, 
            chats, setChats, 
            messageIsLoading, setMessageIsLoading, 
            dots, setDots, 
            messageIsLoadingDetails, setMessageIsLoadingDetails, 
            currentChatId, setCurrentChatId, 
            currentChatName, setCurrentChatName, 
            sources, setSources, 
            openSources, setOpenSources, 
            setPaper, paper, 
            isOpen, setIsOpen,
            isChatbotOpen, setIsChatbotOpen
        }}>
            <div className="flex h-screen overflow-hidden">
                <FocusSidebar sidebarOpen={isOpen} setSidebarOpen={setIsOpen} />
                
                {/* Main Content */}
                <div 
                    className="flex-1 relative"
                    style={{
                        marginLeft: isOpen ? '-55px' : '-40px',
                        transition: 'margin-left 0.3s ease-in-out',
                    }}
                >
                    <Helmet>
                        <title>Freiya Focus</title>
                    </Helmet>
                    <FocusContent />
                </div>
            </div>
        </UserContext.Provider>
    );
}