import { LuAward } from "react-icons/lu";
import React from 'react';
import { CircularProgress } from "@mui/joy";
import { IoSend } from "react-icons/io5";
import { FaLightbulb } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import { Tooltip } from "@mui/material";

import axios from 'axios';
import { UserContext } from '../pages/Chat';
import { calcMaxInputLength } from '../utils/utils';
import { SparkAICall } from "./helpers";
import PromptTipsModal from './PromptTipsModal';


export default function ChatInput({ enableChatInput }) {
    const { chatInput, setChatInput } = React.useContext(UserContext);
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { messageIsLoadingDetails, setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const { openSources, setOpenSources } = React.useContext(UserContext);

    const { sources, setSources } = React.useContext(UserContext);

    const { model } = React.useContext(UserContext);

    const [promptTipsOpen, setPromptTipsOpen] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (messageIsLoading) {
            return;
        }

        await handleChatSubmission({
            chatInput,
            messages,
            setMessages,
            setMessageIsLoading,
            setChatInput,
            model,
            currentChatId
        });
    };

    const [textareaHeight, setTextareaHeight] = React.useState(50); // Default height of the textarea
    const textareaRef = React.useRef(null);

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to calculate correct scrollHeight
            const newHeight = textareaRef.current.scrollHeight;
            // Cap the height at 150px
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px"; // Maximum height
                textareaRef.current.style.overflowY = "auto"; // Add scrollbar when exceeding max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(150); // Update height state for animation
            } else {
                textareaRef.current.style.height = `${newHeight}px`; // Allow auto-resizing below 150px
                textareaRef.current.style.overflowY = "hidden"; // Hide scrollbar when below max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(newHeight); // Update height state for animation
            }
        }
    }, [chatInput]);    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line on Enter
            handleSubmit(e); // Trigger form submission
        }
    };
    

    return (
        <>
            {enableChatInput && (
                <div className="fixed bottom-0 w-full bg-gradient-to-t from-white via-white to-transparent pb-6 pt-4 z-20">
                    <motion.div 
                        className="w-full flex justify-center"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <form
                            onSubmit={handleSubmit}
                            className="w-full max-w-4xl px-4 relative"
                        >
                            <div className="relative group">
                                <div className="relative">
                                    <motion.div
                                        className="absolute inset-0 rounded-2xl bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-md transition-all duration-300"
                                        animate={{
                                            scale: [1, 1.02, 1],
                                        }}
                                        transition={{
                                            duration: 4,
                                            repeat: Infinity,
                                        }}
                                    />
                                    <div className="relative bg-white rounded-2xl border border-gray-200 focus-within:border-emerald-500 hover:border-emerald-500/50 transition-all duration-300 shadow-sm">
                                        {/* Model Indicator section */}
                                        <div className="px-4 pt-2.5 pb-1 flex items-center justify-between">
                                            <div className="flex items-center gap-2 text-xs text-gray-500">
                                                <div className="flex items-center gap-1.5">
                                                    <div className="w-2 h-2 rounded-full bg-emerald-500" />
                                                    <span className="font-medium">
                                                        {model === 'apex' ? 'Freiya Nova (<1min)' : model === 'smart' ? 'Freiya Pro (<40s)' : 'Freiya mini (<20s)'}
                                                    </span>
                                                </div>
                                                •
                                                <span>Start typing</span>
                                            </div>
                                            
                                            {/* Prompt Tips Button */}
                                            <Tooltip title="Get tips for better responses" placement="top" arrow>
                                                <motion.button
                                                    type="button"
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    onClick={() => setPromptTipsOpen(true)}
                                                    className="flex items-center gap-1.5 px-2.5 py-1.5 rounded-lg text-amber-500 hover:bg-amber-50 transition-colors"
                                                >
                                                    <span className="text-xs font-medium">Prompt Tips</span>
                                                    <FaLightbulb className="w-3.5 h-3.5" />
                                                </motion.button>
                                            </Tooltip>
                                        </div>

                                        <div className="relative flex items-end">
                                            <textarea
                                                ref={textareaRef}
                                                required
                                                value={chatInput}
                                                onChange={(e) => setChatInput(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                disabled={messageIsLoading}
                                                placeholder={messageIsLoading ? "Freiya is thinking..." : "Ask anything..."}
                                                className="w-full pl-4 pr-16 py-3 outline-none rounded-2xl resize-none placeholder:text-gray-400"
                                                style={{
                                                    minHeight: '56px',
                                                    maxHeight: '200px'
                                                }}
                                            />
                                            
                                            {/* Character count and Send button container */}
                                            <div className="absolute right-2 bottom-2.5 flex items-center gap-2">
                                                {chatInput.length > 0 && (
                                                    <motion.div 
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        className="text-xs text-gray-400"
                                                    >
                                                        {chatInput.length}/4000
                                                    </motion.div>
                                                )}
                                                
                                                <motion.button
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    type="submit"
                                                    disabled={messageIsLoading}
                                                    className={`relative flex-shrink-0 p-2 rounded-xl transition-all duration-300 ${
                                                        messageIsLoading
                                                            ? 'bg-gray-100 text-gray-400'
                                                            : 'bg-gradient-to-r from-emerald-500 to-green-500 text-white shadow-sm hover:shadow-md hover:shadow-emerald-500/20'
                                                    }`}
                                                >
                                                    {messageIsLoading ? (
                                                        <motion.div
                                                            animate={{ rotate: 360 }}
                                                            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                                                        >
                                                            <CircularProgress size="sm" thickness={2} />
                                                        </motion.div>
                                                    ) : (
                                                        <IoSend className="w-4 h-4" />
                                                    )}
                                                </motion.button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </motion.div>
                </div>
            )}

            <AnimatePresence>
                <PromptTipsModal 
                    isOpen={promptTipsOpen} 
                    onClose={() => setPromptTipsOpen(false)} 
                />
            </AnimatePresence>
        </>
    );
}

export const handleExplainConcept = async ({selectedText, actionType, messages, setMessages, setMessageIsLoading, currentChatId, model, actionValue}) => {
    // Set dummy setChatInput
    const setChatInput = (str) => {};

    console.log('[handleExplainConcept] selectedText:', selectedText);
    

    handleChatSubmission({
        chatInput: selectedText,
        messages: messages,
        setMessages: setMessages,
        setMessageIsLoading: setMessageIsLoading,
        setChatInput: setChatInput,
        model: model,
        currentChatId: currentChatId,
        isAction: true,
        actionType: actionType,
        actionValue: actionValue
    });
};

export const handleFindPapers = async ({selectedText, actionType, messages, setMessages, setMessageIsLoading, currentChatId, model, actionValue}) => {
    // Set dummy setChatInput
    const setChatInput = (str) => {};

    handleChatSubmission({
        chatInput: selectedText,
        messages: messages,
        setMessages: setMessages,
        setMessageIsLoading: setMessageIsLoading,
        setChatInput: setChatInput,
        model: model,
        currentChatId: currentChatId,
        isAction: true,
        actionType: actionType,
        actionValue: actionValue
    });
};

// Export the chat submission handler
export const handleChatSubmission = async ({
    chatInput,
    messages,
    setMessages,
    setMessageIsLoading,
    setChatInput,
    model,
    currentChatId,
    isAction=false,
    actionType=null,
    actionValue=null
}) => {
    console.log('[handleSubmit] Input:', chatInput);
    // Validate the input length
    if (calcMaxInputLength(model, chatInput.length)) {
        alert(`Your message is too long. Please try again with a shorter message. Length: ${chatInput.length}`);
        return;
    }

    console.log('[handleSubmit] isAction:', isAction);
    setMessageIsLoading(true);
    setChatInput('');

    try {
        console.log('[handleSubmit] Updating messages...');

        console.log('[handleSubmit] Messages:', messages);
        const updatedMessages = [
            ...messages,
            { role_id: 1, content: chatInput, action: actionType, action_value: actionValue }
        ];

        console.log('[handleSubmit] Updated messages:', updatedMessages);
        setMessages(updatedMessages);

        console.log('[handleSubmit] Preparing SparkAI call...');
        const sparkaiCallResult = await SparkAICall(chatInput, updatedMessages, model, currentChatId, isAction, actionType, actionValue);
        console.log('[handleSubmit] SparkAI call result:', sparkaiCallResult);
        const text = sparkaiCallResult ? sparkaiCallResult.text : null;

        if (sparkaiCallResult.error) {
            return;
        }

        setMessages(prevMessages => [
            ...prevMessages,
            { role_id: 2, content: text }
        ]);
    } catch (error) {
        console.log('[handleSubmit] Error:', error);
        alert('An error occurred while processing your message. Please try again.');
    } finally {
        setMessageIsLoading(false);
    }
};