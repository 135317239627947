import React from "react";
import { motion } from "framer-motion";
import { IoShieldCheckmark } from "react-icons/io5";
import { ProfileMenu } from "./PersistentDrawer";

export default function AccountInfo({ 
    sidebarOpen, 
    setSidebarOpen, 
    mail, 
    variant = "default" // Can be "default", "focus", or "beta"
}) {
    const getStatusColor = () => {
        switch (variant) {
            case "focus":
                return {
                    icon: "text-cyan-500",
                    text: "text-cyan-600"
                };
            case "beta":
            case "default":
            default:
                return {
                    icon: "text-emerald-500",
                    text: "text-emerald-600"
                };
        }
    };

    const getStatusText = () => {
        switch (variant) {
            case "focus":
                return "Active Account";
            case "beta":
                return "Active Account (Beta)";
            default:
                return "Active Account";
        }
    };

    const colors = getStatusColor();

    return (
        <div className={`flex items-center gap-3 ${!sidebarOpen ? 'justify-center' : 'mb-5'}`}>
            <ProfileMenu setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
            {sidebarOpen && (
                <motion.div
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="flex flex-col"
                >
                    <span className="text-sm font-medium text-gray-700 truncate">
                        {mail}
                    </span>
                    <div className="flex items-center gap-1">
                        <IoShieldCheckmark className={`w-3 h-3 ${colors.icon}`} />
                        <span className={`text-xs ${colors.text}`}>{getStatusText()}</span>
                    </div>
                </motion.div>
            )}
        </div>
    );
} 