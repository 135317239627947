import { UserContext } from "../pages/Chat"
import React from "react";
import PersistentDrawer from "./PersistentDrawer";
import TopHeader from "./TopHeader";
import ChatBot from "./ChatBot";
import ChatInput from "./ChatInput";
import SourcesDrawer from "./SourcesDrawer";


export default function ChatContent() {

    const { isOpen, setIsOpen, setCurrentChatName, currentChatName, enableChatInput } = React.useContext(UserContext);

    const { sparkaiSidebarOpen, setSparkaiSidebarOpen } = React.useContext(UserContext);
    const { sourcesDrawerOpen, setSourcesDrawerOpen } = React.useContext(UserContext);

    const { marginLeft, setMarginLeft } = React.useContext(UserContext);
    const [ chatMarginLeft, setChatMarginLeft ] = React.useState(marginLeft);

    React.useEffect(() => {
        if (sparkaiSidebarOpen) {
            setMarginLeft(300);
        } else {
            if (window.innerWidth < 768) {
                setChatMarginLeft(0);
                setMarginLeft(100);
            } else {
                setChatMarginLeft(100);
                setMarginLeft(100);
            }
        }
    }, [window.innerWidth, sparkaiSidebarOpen]);

    
    const extraMargin = 15;
    const sourcesDrawerSize = 450;
    const sourcesDrawerMargin = 15;
    const sourcesMarginLeft = sourcesDrawerOpen ? sourcesDrawerSize + sourcesDrawerMargin + extraMargin : 0;
    const sourcesMarginRight = sourcesDrawerOpen ? sourcesDrawerSize : 0;

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    const closeSourcesDrawer = () => {
        setSourcesDrawerOpen(false)
    }

    return (
        <div 
            className="flex flex-col h-screen"
            style={{
                marginRight: sourcesMarginRight,
                transition: 'margin-right 0.3s ease-in-out'
            }}
        >
            <main 
                className='h-full'
                style={{marginLeft: chatMarginLeft, transition: 'margin-left 0.3s', marginRight: sourcesMarginLeft, transition: 'margin-right 0.3s'}}
            >
                <TopHeader isOpen={isOpen} toggleDrawer={toggleDrawer} currentChatName={currentChatName} marginLeft={marginLeft}/>
                <div
                    className='step1 flex flex-col justify-center items-center h-full' 
                >
                    <ChatBot />
                    <ChatInput enableChatInput={enableChatInput}/>
                    <text 
                        className="text-gray-500 text-xs text-center fixed bottom-0 z-30"
                    >
                        SparkAI can make mistakes, always check references. Made by&nbsp;
                        <a 
                            className="text-blue-500 hover:cursor-pointer hover:underline"
                        href="https://ederspark.com" target="_blank" rel="noreferrer">
                            EderSpark
                        </a>
                    </text>
                </div>
            </main>
            <SourcesDrawer isOpen={sourcesDrawerOpen} drawerSize={sourcesDrawerSize} drawerMargin={sourcesDrawerMargin} closeSourcesDrawer={closeSourcesDrawer}/>
        </div>
    )
}