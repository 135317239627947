import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose, IoNewspaper } from 'react-icons/io5';
import { FaArrowRight, FaStar } from 'react-icons/fa6';

const newsUpdates = [
    {
        badge: "NEW",
        title: "Introducing Nova Model",
        description: "Experience enhanced reasoning capabilities with our latest Nova model. Try it now for more accurate and detailed responses.",
        date: "",
        link: "#",
        slug: "nova-model"
    },
    {
        badge: "COMING SOON",
        title: "Streaming Responses",
        description: "We're adding real-time streaming responses for faster, more dynamic interactions with Freiya. Experience answers as they're generated.",
        date: "29/01/2025"
    },
    {
        badge: "UPDATE",
        title: "Improved Citation System",
        description: "We've enhanced our citation system to provide more accurate and relevant sources for your queries.",
        date: "22/01/2025"
    },
];

export default function NewsModal({ isOpen, onClose }) {

    const [news, setNews] = React.useState(null);
    const [loadingNews, setLoadingNews] = React.useState(true);

    const parseDateToReadable = (date) => {
        // Will parse dates to "2 days ago", "1 week ago", "Just now"
        if (!date) return "";
        
        const now = new Date();
        const dateObj = new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")); // Convert DD/MM/YYYY to MM/DD/YYYY
        
        if (isNaN(dateObj.getTime())) return "";
        
        const diffTime = Math.abs(now - dateObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 0) return "Just now";
        if (diffDays === 1) return "1 day ago";
        if (diffDays >= 7) {
            const weeks = Math.floor(diffDays / 7);
            return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
        }
        return `${diffDays} days ago`;
    }

    React.useEffect(() => {
        const parsedNews = newsUpdates.map((item) => ({
            ...item,
            date: parseDateToReadable(item.date)
        }));
        setNews(parsedNews);
        setLoadingNews(false);
    }, []);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/20 backdrop-blur-sm z-[100] flex items-center justify-center p-4"
                    onClick={onClose}
                    style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                    <motion.div
                        initial={{ scale: 0.95, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.95, opacity: 0 }}
                        onClick={(e) => e.stopPropagation()}
                        className="relative w-full max-w-2xl bg-white rounded-2xl shadow-xl overflow-y-auto max-h-[90vh]"
                    >
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onClose}
                            className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
                        >
                            <IoClose className="w-5 h-5 text-gray-500" />
                        </motion.button>

                        <div className="p-6">
                            <div className="relative rounded-xl bg-gradient-to-r from-emerald-500 to-green-500 p-5 mb-6 overflow-hidden">
                                <motion.div
                                    className="absolute inset-0 bg-white opacity-10"
                                    animate={{
                                        scale: [1, 1.2, 1],
                                        opacity: [0.1, 0.15, 0.1],
                                    }}
                                    transition={{ duration: 4, repeat: Infinity }}
                                />
                                <div className="relative flex items-center gap-4">
                                    <div className="p-3 bg-white/20 rounded-lg backdrop-blur-sm">
                                        <IoNewspaper className="w-6 h-6 text-white" />
                                    </div>
                                    <div>
                                        <h2 className="text-xl font-semibold text-white">Latest Updates</h2>
                                        <p className="text-emerald-100">Stay up to date with Freiya</p>
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-6">
                                {loadingNews ? (
                                    <div className="flex justify-center items-center h-full">
                                        <div className="w-8 h-8 rounded-full animate-spin border-4 border-emerald-500 border-t-transparent"></div>
                                    </div>
                                ) : (
                                    news.map((item, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: index * 0.1 }}
                                        className="relative overflow-hidden rounded-xl border border-emerald-100 bg-gradient-to-r from-emerald-50 to-green-50 p-4"
                                    >
                                        <div className="flex items-start gap-4">
                                            <div className="flex-shrink-0">
                                                {item.badge && (
                                                    <span className="inline-block px-2 py-1 text-xs font-medium text-emerald-600 bg-emerald-100 rounded-full">
                                                        {item.badge}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                <h3 className="font-semibold text-gray-800 mb-1">{item.title}</h3>
                                                <p className="text-sm text-gray-600">{item.description}</p>
                                                {item.link && (
                                                    <motion.div
                                                        whileHover={{ scale: 1.02 }}
                                                        whileTap={{ scale: 0.98 }}
                                                        onClick={() => {
                                                            // Navigate to the article page
                                                            window.location.href = `/news/${item.slug}`;
                                                        }}
                                                        className="cursor-pointer"
                                                    >
                                                        <a
                                                            href={item.link}
                                                            className="inline-flex items-center gap-1 text-sm text-emerald-600 hover:text-emerald-700 mt-2"
                                                        >
                                                            Learn more
                                                            <FaArrowRight className="w-3 h-3" />
                                                        </a>
                                                    </motion.div>
                                                )}
                                            </div>
                                            <div className="text-xs text-gray-400">
                                                {item.date}
                                            </div>
                                            </div>
                                        </motion.div>
                                    ))
                                )}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
} 