import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
import { FaHeart, FaArrowRight, FaStar } from 'react-icons/fa6';

export default function FeedbackModal({ isOpen, onClose }) {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center p-4"
                    onClick={onClose}
                >
                    <motion.div
                        initial={{ scale: 0.95, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.95, opacity: 0 }}
                        onClick={(e) => e.stopPropagation()}
                        className="relative w-full max-w-lg bg-white rounded-2xl shadow-xl"
                    >
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onClose}
                            className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
                        >
                            <IoClose className="w-5 h-5 text-gray-500" />
                        </motion.button>

                        <div className="p-6">
                            <div className="relative rounded-xl bg-gradient-to-r from-pink-500 to-rose-500 p-5 mb-6 overflow-hidden">
                                <motion.div
                                    className="absolute inset-0 bg-white opacity-10"
                                    animate={{
                                        scale: [1, 1.2, 1],
                                        opacity: [0.1, 0.15, 0.1],
                                    }}
                                    transition={{ duration: 4, repeat: Infinity }}
                                />
                                <div className="relative flex items-center gap-4">
                                    <div className="p-3 bg-white/20 rounded-lg backdrop-blur-sm">
                                        <FaHeart className="w-6 h-6 text-white" />
                                    </div>
                                    <div>
                                        <h2 className="text-xl font-semibold text-white">Share Your Thoughts!</h2>
                                        <p className="text-pink-100">Help us improve Freiya</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-6">
                                <p className="text-gray-600">
                                    Your feedback is invaluable to us! Share your experience and suggestions to help us make Freiya even better for everyone.
                                </p>
                            </div>

                            <motion.a
                                href="https://forms.gle/your-feedback-form-url"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="block group mb-3"
                            >
                                <div className="relative overflow-hidden rounded-xl border border-pink-100 bg-gradient-to-r from-pink-50 to-rose-50 p-4">
                                    <motion.div
                                        className="absolute inset-0 bg-gradient-to-r from-pink-500/10 via-rose-500/10 to-pink-500/10"
                                        animate={{
                                            x: ['0%', '100%', '0%'],
                                        }}
                                        transition={{
                                            duration: 3,
                                            ease: "linear",
                                            repeat: Infinity,
                                        }}
                                    />
                                    <div className="relative flex items-center justify-between">
                                        <div className="flex items-center gap-3">
                                            <div className="p-2 bg-pink-100 rounded-lg">
                                                <FaStar className="w-5 h-5 text-pink-600" />
                                            </div>
                                            <div>
                                                <p className="font-medium text-gray-800">Share Feedback</p>
                                                <p className="text-sm text-pink-600">Rate your experience and suggestions</p>
                                            </div>
                                        </div>
                                        <FaArrowRight className="w-5 h-5 text-pink-500 transform group-hover:translate-x-1 transition-transform" />
                                    </div>
                                </div>
                            </motion.a>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
} 