import { Skeleton, Stack, Typography, Rating } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../index.css';
import * as React from 'react';
import axios from 'axios';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'

import { VscReferences } from "react-icons/vsc";
import { CircularProgress } from "@mui/joy";
import { ArrowPathIcon, DocumentCheckIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';
import SparkAILogo from '../assets/sparkai_new_only_logo.png';
import { IoSend } from "react-icons/io5";
import HelixLoader from '../common_components/HelixLoader.jsx';
import { CustomMarkdownRenderer } from './ResponseRenderer';

import { UserContext } from '../pages/Chat';
import { MainUserContext } from '../App';
import { replaceCustomMarkdown, calcMaxInputLength } from '../utils/utils';
import { createChatName, createConversation, SparkAICall } from './helpers';
import 'ldrs/helix'

import confetti from 'canvas-confetti';
import ResponseRenderer from './ResponseRenderer';
import { FaLightbulb } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import PromptTipsModal from './PromptTipsModal';

// Enhanced FollowUpQuestions component with API integration, loading state, and collapsible UI
function FollowUpQuestions({ onQuestionSelected, aiResponse, messageId }) {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedQuestion, setSelectedQuestion] = React.useState(null);
    const [activeCategory, setActiveCategory] = React.useState("gaps");
    const [questionData, setQuestionData] = React.useState(null);
    const [error, setError] = React.useState(null);
    
    // Access the setChatInput function from context
    const { setChatInput } = React.useContext(UserContext);
    const { chatId } = React.useContext(MainUserContext);
    
    // Fallback questions in case API fails
    const fallbackQuestions = {
        "gaps": [
            "What are the major limitations in the current research on this topic?",
            "Which aspects of this issue remain unexplored or understudied?",
            "What conflicting evidence exists that needs reconciliation?"
        ],
        "practical": [
            "How can these findings be applied in a clinical or professional setting?",
            "What practical interventions could be developed based on this research?",
            "How might implementation differ across different contexts or populations?"
        ],
        "methodological": [
            "What methodological innovations could improve research in this area?",
            "How do different research methods affect findings on this topic?",
            "What challenges exist in measuring or quantifying key variables?"
        ],
        "comparative": [
            "How do these findings compare to related research in other fields?",
            "What contradictory evidence exists from alternative perspectives?",
            "How have approaches to this topic evolved over time?"
        ],
        "future": [
            "What are the most promising future research directions in this field?",
            "How might technological advances change our understanding of this topic?",
            "What interdisciplinary approaches could yield new insights?"
        ]
    };
    
    const categoryInfo = {
        gaps: {
            title: "Address Research Gaps",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.679zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clipRule="evenodd" />
                </svg>
            ),
            color: "amber",
            description: "Identify and explore unanswered questions"
        },
        practical: {
            title: "Practical Applications",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                </svg>
            ),
            color: "emerald",
            description: "Translate research into real-world applications"
        },
        methodological: {
            title: "Methodological Insights",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M10.5 3.798v5.02a3 3 0 01-.879 2.121l-2.377 2.377a9.845 9.845 0 015.091 1.013 8.315 8.315 0 005.713.636l.285-.071-3.954-3.955a3 3 0 01-.879-2.121v-5.02a23.614 23.614 0 00-3 0zm4.5.138a.75.75 0 00.093-1.495A24.837 24.837 0 0012 2.25a25.048 25.048 0 00-3.093.191A.75.75 0 009 3.936v4.882a1.5 1.5 0 01-.44 1.06l-6.293 6.294c-1.62 1.621-.903 4.475 1.471 4.88 2.686.46 5.447.698 8.262.698 2.816 0 5.576-.239 8.262-.697 2.373-.406 3.092-3.26 1.47-4.881L15.44 9.879A1.5 1.5 0 0115 8.818V3.936z" clipRule="evenodd" />
                </svg>
            ),
            color: "blue",
            description: "Explore research design and methods"
        },
        comparative: {
            title: "Comparative Analysis",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z" clipRule="evenodd" />
                </svg>
            ),
            color: "violet",
            description: "Compare findings with other research"
        },
        future: {
            title: "Future Directions",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" clipRule="evenodd" />
                    <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
                </svg>
            ),
            color: "indigo",
            description: "Look ahead to emerging trends"
        }
    };
    
    // Animation variants
    const containerVariants = {
        collapsed: { height: 0, opacity: 0 },
        expanded: { height: "auto", opacity: 1, transition: { duration: 0.3 } }
    };
    
    const childVariants = {
        collapsed: { opacity: 0, y: -5 },
        expanded: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.1 } }
    };
    
    // Handler for expanding/collapsing
    const handleExpand = () => {
        if (!isExpanded && !questionData && !isLoading) {
            // First time expanding - fetch questions
            fetchQuestions();
        }
        setIsExpanded(!isExpanded);
    };
    
    // Fetch questions from the API
    const fetchQuestions = async () => {
        if (!aiResponse || isLoading) return;
        
        setIsLoading(true);
        setError(null);
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ia/generate_research_questions`, {
                ai_response: aiResponse
            });
            
            const generatedQuestions = response.data.questions;
            setQuestionData(generatedQuestions);

            // Save the generated questions to the database if we have a messageId
            if (messageId) {
                try {
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/message/research_questions`, {
                        messageId: messageId,
                        research_questions: response.data.raw_response
                    });
                    console.log('Research questions saved to database');
                } catch (saveErr) {
                    console.error('Error saving research questions:', saveErr);
                    // Continue with the questions we have, just log the error
                }
            }
        } catch (err) {
            console.error('Error generating research questions:', err);
            setError('Failed to generate questions. Using default questions instead.');
            setQuestionData(fallbackQuestions);
        } finally {
            setIsLoading(false);
        }
    };
    
    // Handle question selection - UPDATED to set chat input and trigger submission
    const handleQuestionClick = (category, index) => {
        const questionId = `${category}-${index}`;
        const question = questionData[category][index];
        
        // Set the selected question for visual indication
        setSelectedQuestion(questionId);
        
        // Call the callback with question info first
        if (onQuestionSelected) {
            onQuestionSelected(question, category);
        }
        
        // Fill the chat input with the selected question and trigger form submission
        if (setChatInput) {
            setChatInput(question);
            
            // Allow a short delay for the chat input to update before submitting
            setTimeout(() => {
                // Find and click the submit button
                const submitButton = document.querySelector('form button[type="submit"]');
                if (submitButton && !submitButton.disabled) {
                    submitButton.click();
                }
            }, 200);
        } else {
            console.log("No chat input set");
        }
    };
    
    // Use effect to check if questions are already available in the message
    React.useEffect(() => {
        if (messageId && aiResponse && !questionData && !isLoading) {
            // If the component is rendered with a messageId, check if questions already exist
            const checkExistingQuestions = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/research_questions/${messageId}`);
                    if (response.data && response.data.research_questions) {
                        console.log('Using existing research questions from message');
                        setQuestionData(response.data.research_questions);
                        setIsExpanded(true);
                    }
                } catch (err) {
                    console.error('Error fetching message data:', err);
                    // If we can't fetch existing questions, we'll generate new ones when expanded
                }
            };
            
            checkExistingQuestions();
        }
    }, [messageId, aiResponse, questionData, isLoading, chatId]);
    
    return (
        <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.4 }}
            className="mt-5 mb-4 border border-gray-100 rounded-xl bg-white shadow-sm w-full mx-auto overflow-hidden"
        >
            {/* Header with expand/collapse button */}
            <div 
                className="flex items-center justify-between p-3 cursor-pointer hover:bg-gray-50 transition-colors"
                onClick={handleExpand}
            >
                <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-indigo-100 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3.5 h-3.5 text-indigo-600">
                            <path fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="flex items-center gap-2">
                        <h3 className="text-sm font-medium text-gray-800">Research Objective Questions</h3>
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    {isLoading && (
                        <div className="flex items-center gap-2 text-xs text-gray-500">
                            <svg className="animate-spin h-4 w-4 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Generating questions...</span>
                        </div>
                    )}
                    {isExpanded ? (
                        <button 
                            className="p-1 rounded-md text-gray-500 hover:bg-gray-100 focus:outline-none"
                            aria-label="Collapse research questions"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clipRule="evenodd" />
                            </svg>
                        </button>
                    ) : (
                        <button 
                            className="py-1 px-2.5 text-xs font-medium bg-indigo-50 text-indigo-600 rounded-full border border-indigo-200 hover:bg-indigo-100 transition-colors flex items-center gap-1.5"
                            aria-label="Explore research questions"
                        >
                            <span>Explore Research Directions</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5">
                                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                            </svg>
                        </button>
                    )}
                </div>
            </div>
            
            {/* Expandable content */}
            {isExpanded && (
                <motion.div
                    variants={containerVariants}
                    initial="collapsed"
                    animate="expanded"
                    exit="collapsed"
                    className="px-3 pb-3"
                >
                    {/* Error message if API call failed */}
                    {error && (
                        <motion.div 
                            variants={childVariants}
                            className="mb-3 px-3 py-2 bg-amber-50 border border-amber-200 text-amber-700 rounded-md text-xs"
                        >
                            <div className="flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-amber-500">
                                    <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                </svg>
                                <span>{error}</span>
                            </div>
                        </motion.div>
                    )}
                    
                    {/* Loading animation when fetching data */}
                    {isLoading && (
                        <motion.div 
                            variants={childVariants}
                            className="flex flex-col items-center justify-center py-8"
                        >
                            <div className="w-12 h-12 rounded-full border-4 border-indigo-100 border-t-indigo-500 animate-spin"></div>
                            <p className="mt-4 text-sm text-gray-600">Analyzing the response...</p>
                            <p className="text-xs text-gray-500 mt-1">This may take a few moments</p>
                        </motion.div>
                    )}
                    
                    {/* Questions content when loaded */}
                    {!isLoading && questionData && (
                        <>
                            {/* Category selector - horizontal pill buttons */}
                            <motion.div 
                                variants={childVariants}
                                className="flex flex-wrap gap-1 mb-3"
                            >
                                {Object.entries(categoryInfo).map(([key, category]) => (
                                    <button
                                        key={key}
                                        onClick={() => setActiveCategory(key)}
                                        className={`px-2 py-1 rounded-full text-xs flex items-center gap-1 transition-all ${
                                            activeCategory === key
                                                ? `bg-${category.color}-100 text-${category.color}-700 border border-${category.color}-200`
                                                : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-transparent'
                                        }`}
                                    >
                                        <span className={`text-${category.color}-600`}>{category.icon}</span>
                                        <span className="font-medium">{category.title}</span>
                                    </button>
                                ))}
                            </motion.div>
                            
                            {/* Description of selected category */}
                            <motion.div 
                                variants={childVariants}
                                className={`mb-3 px-2 py-1.5 bg-${categoryInfo[activeCategory].color}-50 text-${categoryInfo[activeCategory].color}-700 rounded-md text-xs`}
                            >
                                <div className="flex items-center gap-1.5">
                                    <span className={`text-${categoryInfo[activeCategory].color}-600`}>
                                        {categoryInfo[activeCategory].icon}
                                    </span>
                                    <span>{categoryInfo[activeCategory].description}</span>
                                </div>
                            </motion.div>
                            
                            {/* Questions for selected category */}
                            <motion.div 
                                variants={childVariants}
                                className="space-y-1.5"
                            >
                                {questionData[activeCategory] && questionData[activeCategory].map((question, index) => {
                                    // Function to parse text and make asterisk-wrapped content bold
                                    const formatText = (text) => {
                                        if (!text) return '';
                                        // Split by asterisks and map to either regular text or bold
                                        const parts = text.split(/(\*[^*]+\*)/g);
                                        return parts.map((part, i) => {
                                            if (part.startsWith('*') && part.endsWith('*')) {
                                                // Remove asterisks and make bold
                                                return <strong key={i}>{part.slice(1, -1)}</strong>;
                                            }
                                            return part;
                                        });
                                    };
                                    
                                    return (
                                    <motion.button
                                        key={index}
                                        variants={childVariants}
                                        className={`text-left px-3 py-2 rounded-md border w-full transition-all duration-200 ${
                                            selectedQuestion === `${activeCategory}-${index}` 
                                                ? `bg-${categoryInfo[activeCategory].color}-50 border-${categoryInfo[activeCategory].color}-200` 
                                                : 'bg-white border-gray-200 hover:border-gray-300'
                                        }`}
                                        onClick={() => handleQuestionClick(activeCategory, index)}
                                        whileHover={{ scale: 1.01 }}
                                        whileTap={{ scale: 0.99 }}
                                    >
                                        <div className="flex items-start gap-2">
                                            <div className={`mt-0.5 w-4 h-4 rounded-full flex items-center justify-center flex-shrink-0 ${
                                                selectedQuestion === `${activeCategory}-${index}` 
                                                    ? `bg-${categoryInfo[activeCategory].color}-500 text-white` 
                                                    : 'bg-gray-100 text-gray-400'
                                            }`}>
                                                <span className="text-[10px] font-medium">{index + 1}</span>
                                            </div>
                                            <p className={`text-xs ${
                                                selectedQuestion === `${activeCategory}-${index}` 
                                                    ? `text-${categoryInfo[activeCategory].color}-700 font-medium` 
                                                    : 'text-gray-700'
                                            }`}>
                                                {formatText(question)}
                                            </p>
                                        </div>
                                    </motion.button>
                                    );
                                })}
                            </motion.div>
                        </>
                    )}
                </motion.div>
            )}
        </motion.div>
    );
}

function ActionMessage({ actionType, actionValue, content }) {
    React.useEffect(() => {
        console.log(`[ActionMessage] actionType: ${actionType} actionValue: ${actionValue} content: ${content}`);
    }, [actionType, actionValue, content]);

    if (actionType === "EXPLAIN_CONCEPT") {
        return (
            <div className="flex flex-col gap-4 p-4 rounded-xl border border-emerald-200 bg-gradient-to-br from-emerald-50/50 to-white">
                <div className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-emerald-100">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-emerald-600">
                            <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.707 9.707 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-semibold text-gray-800">Action Requested</span>
                        <span className="text-sm text-gray-500">Explain: {actionValue}</span>
                    </div>
                </div>
            </div>
        )
    }
    else if (actionType === "FIND_PAPERS") {
        return (
            <div className="flex flex-col gap-4 p-4 rounded-xl border border-blue-200 bg-gradient-to-br from-blue-50/50 to-white">
                <div className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-blue-100">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-blue-600">
                            <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                            <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                            <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                        </svg>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-semibold text-gray-800">Research Requested</span>
                        <span className="text-sm text-gray-500">Find papers about: {actionValue}</span>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <Markdown
                remarkPlugins={[[remarkGfm], [remarkMath]]} 
                rehypePlugins={[[rehypeKatex], [rehypeRaw]]}
                className="text-lg md:text-[0.95rem] text-gray-800 md:leading-10"
            >
                {content}
            </Markdown>
        )
    }
}

function UserMessage({ content, actionType, actionValue }) {
    return (
        <div className="message-container-user relative">
            <div className="message-user w-full relative flex flex-col gap-2">
                <ActionMessage 
                    actionType={actionType} 
                    actionValue={actionValue} 
                    content={content} 
                />
            </div>
        </div>
    );
}

function BotMessage({ content, lastSecondsElapsed, messageId, reasoning, citations, isLastMessage }) {
    const [rating, setRating] = React.useState(0);
    const [markdownReasoning, setMarkdownReasoning] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [height, setHeight] = React.useState(0);
    const contentRef = React.useRef(null);
    const [nUsedCitations, setNUsedCitations] = React.useState(null);

    React.useEffect(() => {
        if (reasoning) {
            CustomMarkdownRenderer({ text: reasoning }).then(setMarkdownReasoning);
        }
    }, [reasoning]);

    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/rating/${messageId}`)
                .then(response => {
                    if (response.data.status === "success") {
                        setRating(response.data.rating || 0);
                    }
                })
                .catch(error => {
                    console.error('Error fetching rating:', error);
                });
        }
    }, [messageId]);

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
        axios.post(process.env.REACT_APP_API_URL + '/api/messages/rate', {
            message_id: messageId,
            rating: newValue
        });

        if (newValue >= 4) {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
    };

    const toggleAccordion = () => {
        if (isOpen) {
            setHeight(0);
            setTimeout(() => setIsOpen(false), 300);
        } else {
            setIsOpen(true);
            setHeight(contentRef.current?.scrollHeight || 0);
        }
    };

    const totalSources = Object.keys(citations || {}).length || Math.max(2, Math.floor(lastSecondsElapsed/3));
    const relevantSources = nUsedCitations || Math.ceil(totalSources * 0.7);
    const relevancyPercentage = Math.round((relevantSources / totalSources) * 100);

    // Add new code to analyze years
    const getYearAnalysis = () => {
        const currentYear = new Date().getFullYear();
        const years = Object.values(citations || {}).map(c => c.year || currentYear);
        const avgYear = Math.round(years.reduce((a, b) => a + b, 0) / years.length);
        const oldestYear = Math.min(...years);
        const newestYear = Math.max(...years);
        
        let timelinessLabel;
        if (avgYear >= currentYear - 2) timelinessLabel = "Very Recent Information";
        else if (avgYear >= currentYear - 5) timelinessLabel = "Recent Information";
        else if (avgYear >= currentYear - 10) timelinessLabel = "Established Information";
        else timelinessLabel = "Foundational Information";

        return { avgYear, oldestYear, newestYear, timelinessLabel };
    };

    const yearAnalysis = getYearAnalysis();

    // Add this handler to process selected follow-up questions
    const handleFollowUpQuestion = (questionText, categoryKey) => {
        console.log("Selected follow-up question:", questionText, "Category:", categoryKey);
        // The actual setting of chatInput is now handled in handleQuestionClick
    };

    return (
        <div className="message-container-bot relative">
            <div className={`absolute left-0 flex items-center gap-4 mb-2 opacity-90 hover:opacity-100 transition-opacity duration-300 justify-start w-full z-10`}>
                {isLastMessage && lastSecondsElapsed > 0 && (
                    <div className="flex items-center gap-2 px-3 py-1.5 backdrop-blur-sm rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-400">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm text-gray-600 font-medium tracking-wide">
                            Thought for {Math.round(lastSecondsElapsed)}s
                        </span>
                    </div>
                )}
                <Tooltip 
                    title={
                        <div className="p-2">
                            <div className="flex items-center gap-2 mb-2">
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 24 24" 
                                    fill="currentColor" 
                                    className="w-5 h-5 text-emerald-600"
                                >
                                    <path fillRule="evenodd" d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z" clipRule="evenodd" />
                                </svg>
                                <span className="font-semibold text-gray-800">Source Analysis</span>
                            </div>
                            <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Total Papers Analyzed:</span>
                                    <span className="font-medium text-gray-800">{totalSources}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Key Papers:</span>
                                    <span className="font-medium text-gray-800">{relevantSources}</span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div 
                                        className="bg-emerald-500 h-2 rounded-full transition-all duration-500"
                                        style={{ width: `${relevancyPercentage}%` }}
                                    />
                                </div>
                                <div className="flex justify-between items-center text-sm">
                                    <span className="text-emerald-600 font-medium">{relevancyPercentage}% Direct Contribution</span>
                                </div>
                                <div className="mt-2 text-xs text-gray-600">
                                    All papers were carefully analyzed. {relevantSources} papers directly contributed to this response, while others provided valuable context and verification.
                                </div>
                            </div>
                        </div>
                    }
                    arrow
                    placement="top"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#ffffff',
                                '& .MuiTooltip-arrow': {
                                    color: '#ffffff',
                                },
                                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
                            },
                        },
                    }}
                >
                    <div className="relative left-0 flex items-center gap-2 px-3 py-1.5 bg-emerald-50 rounded-md border border-emerald-200 shadow-sm hover:bg-emerald-100 transition-colors duration-200 cursor-help">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 24 24" 
                            fill="currentColor" 
                            className="w-4 h-4 text-emerald-600"
                        >
                            <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                            <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                            <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                        </svg>
                        <span className="text-sm font-medium text-gray-600">
                            Analyzed {totalSources} papers
                        </span>
                        <div className="absolute -top-1 -right-1 h-2 w-2 bg-emerald-400 rounded-full animate-ping" />
                        <div className="absolute -top-1 -right-1 h-2 w-2 bg-emerald-500 rounded-full" />
                    </div>
                </Tooltip>
                <Tooltip 
                    title={
                        <div className="p-2">
                            <div className="flex items-center gap-2 mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-blue-600">
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clipRule="evenodd" />
                                </svg>
                                <span className="font-semibold text-gray-800">Timeline Analysis</span>
                            </div>
                            <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Average Year:</span>
                                    <span className="font-medium text-gray-800">{yearAnalysis.avgYear}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Range:</span>
                                    <span className="font-medium text-gray-800">{yearAnalysis.oldestYear} - {yearAnalysis.newestYear}</span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div 
                                        className="bg-blue-500 h-2 rounded-full transition-all duration-500"
                                        style={{ width: `${((yearAnalysis.avgYear - yearAnalysis.oldestYear) / (yearAnalysis.newestYear - yearAnalysis.oldestYear)) * 100}%` }}
                                    />
                                </div>
                                <div className="mt-2 text-xs text-gray-600">
                                    This response draws from {yearAnalysis.timelinessLabel.toLowerCase()}, spanning {yearAnalysis.newestYear - yearAnalysis.oldestYear} years of academic work.
                                </div>
                            </div>
                        </div>
                    }
                    arrow
                    placement="top"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#ffffff',
                                '& .MuiTooltip-arrow': {
                                    color: '#ffffff',
                                },
                                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
                            },
                        },
                    }}
                >
                    <div className="relative left-0 flex items-center gap-2 px-3 py-1.5 bg-blue-50 rounded-md border border-blue-200 shadow-sm hover:bg-blue-100 transition-colors duration-200 cursor-help">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-blue-600">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clipRule="evenodd" />
                        </svg>
                        <span className="text-sm font-medium text-gray-600">
                            {yearAnalysis.timelinessLabel}
                        </span>
                        <div className="absolute -top-1 -right-1 h-2 w-2 bg-blue-400 rounded-full animate-ping" />
                        <div className="absolute -top-1 -right-1 h-2 w-2 bg-blue-500 rounded-full" />
                    </div>
                </Tooltip>
            </div>
            <div className="message-bot w-full relative flex flex-col">
                {reasoning && (
                    <div className="hover:bg-green-50 rounded-2xl p-2 mt-8 hover:cursor-pointer transition-all duration-300">
                        <div className="[&_summary::-webkit-details-marker]:hidden">
                            <div 
                                onClick={toggleAccordion}
                                className="flex justify-between items-center cursor-pointer px-4 py-2"
                            >
                                <div className="flex flex-row gap-2 text-sm md:text-base">
                                    <span className="border border-orange-500 px-2 rounded-md font-mono tracking-widest animated-gradient bg-gradient-to-r from-orange-600 to-yellow-500 bg-clip-text text-transparent">
                                        NOVA
                                    </span>
                                    <span className="font-semibold animated-gradient bg-gradient-to-r from-orange-600 to-yellow-500 bg-clip-text text-transparent">
                                        My Thinking Process <span className="ml-2 text-xs text-green-500 bg-green-100 px-2 rounded-md">Click to expand</span>
                                    </span>
                                </div>
                                <span className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-orange-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </span>
                            </div>
                            <div 
                                style={{ height: isOpen ? `${Math.min(height, 400)}px` : '0px' }}
                                className="overflow-hidden transition-all duration-300 ease-in-out"
                            >
                                <div 
                                    ref={contentRef}
                                    className="px-4 py-2 my-4 overflow-y-auto border-l-[1px] border-orange-500 pl-6 max-h-[400px]"
                                >
                                    <span className="text-sm md:text-sm whitespace-pre-wrap text-gray-500">
                                        <ResponseRenderer 
                                            response={markdownReasoning} 
                                            citations={citations || {}}
                                            showFocus={false}
                                            textSize="text-[0.8rem]"
                                            textColor="gray-500"
                                            showCitations={false}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <ResponseRenderer 
                        response={content} 
                        citations={citations || {}}
                        setNUsedCitations={setNUsedCitations}
                        followupQuestions={
                            <FollowUpQuestions 
                                onQuestionSelected={handleFollowUpQuestion} 
                                aiResponse={content}
                                messageId={messageId}
                            />
                        }
                    />
                </div>
                
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-2 justify-start items-center">
                        <p className="text-sm text-gray-700 font-semibold">
                            Is this answer helpful?
                        </p>
                        <Rating
                            name="message-rating"
                            value={rating}
                            precision={1}
                            size="medium"
                            onChange={handleRatingChange}
                            sx={{
                                '& .MuiRating-iconFilled': {
                                    color: 'lightgreen'
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function MessagesList({ messages }) {
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { messageIsLoadingDetails, setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    const [secondsElapsed, setSecondsElapsed] = React.useState(0);
    const [lastSecondsElapsed, setLastSecondsElapsed] = React.useState(null);

    React.useEffect(() => {
        console.log('[MessagesList] messages: ', messages);
    }, [messages]);

    React.useEffect(() => {
        let interval;
        
        if (messageIsLoading) {
        // Start the interval to update time every 100ms
        interval = setInterval(() => {
            setSecondsElapsed(prev => prev + 0.1); // Increment by 0.1 every 100ms
        }, 100);
        } else {
            // If not processing, clear the timer
            setLastSecondsElapsed(secondsElapsed); // Save last time when processing stops
            setSecondsElapsed(0); // Reset timer when processing stops
        }

        // Clear interval when component unmounts or stops processing
        return () => clearInterval(interval);
    },  [messageIsLoading, currentChatId]);

    return (
        <div
            className='flex flex-col w-full h-full justify-start items-center overflow-y-auto mt-[50px] md:mt-0'
        >
            <div className="flex flex-col gap-5 w-full justify-start items-center mb-[160px]">
                <div className="h-full w-full overflow-x-hidden justify-center flex pt-28">
                    <div className="flex flex-col gap-10 w-11/12 sm:w-[58%] bg-yellow">
                        {messages.map((message, index) => (
                            message.role_id === 1 ? (
                                <UserMessage 
                                    key={index}
                                    content={message.content}
                                    actionType={message.action}
                                    actionValue={message.action_value}
                                />
                            ) : (
                                <BotMessage 
                                    key={index}
                                    content={message.content}
                                    lastSecondsElapsed={10}
                                    messageId={message.message_id}
                                    reasoning={message.reasoning}
                                    citations={message.citations}
                                    isLastMessage={index === messages.length - 1}
                                />
                            )
                        ))}
                    </div>
                </div>
                { messageIsLoading &&
                <div
                    className="flex flex-row mt-8 justify-between items-center w-11/12 sm:w-[58%] mb-20"
                >
                    <div 
                        className="flex flex-row gap-4 justify-start items-center w-full px-2 sm:px-0"
                    >
                        <HelixLoader/>
                        <motion.div
                            key={messageIsLoadingDetails}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            className='text-base md:text-base text-gray-700 text-justify md:leading-10'
                            >
                            <motion.p
                                key={messageIsLoadingDetails}
                                initial={{ opacity: 1 }}
                                animate={{ opacity: [1, 0.5, 1] }}
                                transition={{ duration: 3, repeat: Infinity }}
                                >
                                {messageIsLoadingDetails}
                            </motion.p>
                        </motion.div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

function NoMessages({ createChat }) {
    const { messageIsLoading } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);
    const { model, setModel } = React.useContext(UserContext);
    const [firstMessage, setFirstMessage] = React.useState('');
    const [textareaHeight, setTextareaHeight] = React.useState(50);
    const textareaRef = React.useRef(null);
    const [showApexBadge, setShowApexBadge] = React.useState(true);
    const [promptTipsOpen, setPromptTipsOpen] = React.useState(false);

    const [apexMessagesLeft, setApexMessagesLeft] = React.useState(null);
    const [showApexMessagesLeft, setShowApexMessagesLeft] = React.useState(true);

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/check-apex-messages-left?userId=${userId}`)
            .then(response => {
                const apexMessagesLeft = response.data.apex_messages_left;
                const maxApexMessages = response.data.max_apex_messages;
                console.log('Max apex messages:', maxApexMessages);
                console.log('Apex messages left:', apexMessagesLeft);
                if (maxApexMessages < 100) {
                    console.log('Max apex messages:', maxApexMessages);
                    setShowApexMessagesLeft(true);
                } else {
                    console.log('Showing apex messages left:', apexMessagesLeft);
                    setShowApexMessagesLeft(false);
                }
                setApexMessagesLeft(apexMessagesLeft);
            })
            .catch(error => {
                console.error('Error checking messages left:', error);
            });
    }, [userId]);

    const examplePrompts = [
        {
            icon: "📚",
            text: "Explain quantum entanglement in simple terms",
            category: "Science"
        },
        {
            icon: "💡",
            text: "Help me understand the implications of AI in healthcare",
            category: "Technology"
        },
        {
            icon: "🧬",
            text: "What are the latest developments in CRISPR research?",
            category: "Research"
        }
    ];

    const handleExampleClick = (prompt) => {
        setFirstMessage(prompt);
        textareaRef.current.focus();
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            await createChat({firstMessage: firstMessage, currentChatId: currentChatId});
        }
    };

    const handleApexClick = () => {
        setModel('apex');
        setShowApexBadge(false);
        confetti({
            particleCount: 100,
            spread: 70,
            colors: ['#f97316', '#facc15', '#fb923c'],
            origin: { y: 0.6 }
        });
    };

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            const newHeight = textareaRef.current.scrollHeight;
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px";
                textareaRef.current.style.overflowY = "auto";
                setTextareaHeight(150);
            } else {
                textareaRef.current.style.height = `${newHeight}px`;
                textareaRef.current.style.overflowY = "hidden";
                setTextareaHeight(newHeight);
            }
        }
    }, [firstMessage]);

    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="first-step flex flex-col justify-center items-center rounded-lg gap-4 md:gap-8 p-2 md:p-4 min-h-[500px] w-full max-w-[700px] mx-auto"
        >
            <motion.div 
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col justify-center items-center gap-2 md:gap-4"
            >
                <div className="relative">
                    <img 
                        className='w-10 h-10 md:w-16 md:h-16 animate-pulse'
                        src={SparkAILogo} 
                        alt="Freiya Logo"
                    />
                    <motion.div 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            opacity: [0.5, 0.8, 0.5]
                        }}
                        transition={{ 
                            duration: 2,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                        className={`absolute -inset-2 ${model === 'apex' ? 'bg-orange-100' : 'bg-emerald-100'} rounded-full -z-10`}
                    />
                </div>
                <h1 className={`text-2xl md:text-3xl font-bold text-center bg-gradient-to-r ${
                    model === 'apex' 
                        ? 'from-orange-600 to-yellow-500'
                        : 'from-emerald-600 to-green-600'
                } bg-clip-text text-transparent px-4`}>
                    Freiya, your research copilot
                </h1>
            </motion.div>

            <div className="w-full max-w-2xl px-2 md:px-4">
                <form 
                    onSubmit={async (e) => {
                        e.preventDefault();
                        await createChat({firstMessage: firstMessage, currentChatId: currentChatId});
                    }}
                    className="w-full relative group"
                >
                    <motion.div
                        className="absolute inset-0 rounded-2xl bg-gradient-to-r from-emerald-500/20 via-green-500/20 to-emerald-500/20 opacity-0 group-hover:opacity-100 blur-md transition-all duration-300"
                        animate={{
                            scale: [1, 1.02, 1],
                        }}
                        transition={{
                            duration: 4,
                            repeat: Infinity,
                        }}
                    />
                    <div className="relative bg-white rounded-2xl border border-gray-200 focus-within:border-emerald-500 hover:border-emerald-500/50 transition-all duration-300 shadow-sm">
                        <div className="px-4 pt-2.5 pb-1 flex items-center justify-between">
                            <div className="flex items-center gap-2 text-xs text-gray-500">
                                <div className="flex items-center gap-1.5">
                                    <div className="w-2 h-2 rounded-full bg-emerald-500" />
                                    <span className="font-medium">
                                        {model === 'apex' ? 'Freiya Nova (<1min)' : model === 'smart' ? 'Freiya Pro (<40s)' : 'Freiya mini (<20s)'}
                                    </span>
                                </div>
                                •
                                <span>Start typing</span>
                                {showApexMessagesLeft && model === 'apex' && (
                                    <span className="text-xs text-gray-500 ml-2">
                                        {apexMessagesLeft} apex messages left this month
                                    </span>
                                )}
                            </div>
                            
                            <Tooltip title="Get tips for better responses" placement="top" arrow>
                                <motion.button
                                    type="button"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => setPromptTipsOpen(true)}
                                    className="flex items-center gap-1.5 px-2.5 py-1.5 rounded-lg text-amber-500 hover:bg-amber-50 transition-colors"
                                >
                                    <span className="text-xs font-medium">Prompt Tips</span>
                                    <FaLightbulb className="w-3.5 h-3.5" />
                                </motion.button>
                            </Tooltip>
                        </div>

                        <div className="relative flex items-end">
                            <textarea
                                ref={textareaRef}
                                required
                                value={firstMessage}
                                onChange={(e) => setFirstMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                disabled={messageIsLoading}
                                placeholder={messageIsLoading ? "Freiya is thinking..." : "Ask anything..."}
                                className="w-full pl-4 pr-16 py-3 outline-none rounded-2xl resize-none placeholder:text-gray-400"
                                style={{
                                    minHeight: '56px',
                                    maxHeight: '200px'
                                }}
                            />
                            
                            <div className="absolute right-2 bottom-2.5 flex items-center gap-2">
                                {firstMessage.length > 0 && (
                                    <motion.div 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="text-xs text-gray-400"
                                    >
                                        {firstMessage.length}/4000
                                    </motion.div>
                                )}
                                
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    type="submit"
                                    disabled={messageIsLoading}
                                    className={`relative flex-shrink-0 p-2 rounded-xl transition-all duration-300 ${
                                        messageIsLoading
                                            ? 'bg-gray-100 text-gray-400'
                                            : 'bg-gradient-to-r from-emerald-500 to-green-500 text-white shadow-sm hover:shadow-md hover:shadow-emerald-500/20'
                                    }`}
                                >
                                    {messageIsLoading ? (
                                        <motion.div
                                            animate={{ rotate: 360 }}
                                            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                                        >
                                            <CircularProgress size="sm" thickness={2} />
                                        </motion.div>
                                    ) : (
                                        <IoSend className="w-4 h-4" />
                                    )}
                                </motion.button>
                            </div>
                        </div>
                    </div>
                </form>

                {showApexBadge && (
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="flex justify-center w-full mt-3"
                    >
                        <motion.button
                            onClick={handleApexClick}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="group flex items-center gap-2 px-3 md:px-4 py-2 bg-gradient-to-r from-orange-50 via-yellow-100 to-orange-50 hover:from-orange-100 hover:via-yellow-200 hover:to-orange-100 rounded-full shadow-sm hover:shadow-md transition-all duration-300 border border-orange-200 text-sm md:text-base w-full md:w-auto justify-center"
                        >
                            <span className="text-xs text-orange-500 font-mono border border-orange-300 px-2 py-0.5 rounded">NEW</span>
                            <span className="font-semibold bg-gradient-to-r from-orange-600 to-yellow-500 bg-clip-text text-transparent">Try NOVA Model for enhanced reasoning</span>
                            <motion.span
                                animate={{ x: [0, 5, 0] }}
                                transition={{ duration: 1.5, repeat: Infinity }}
                                className="text-orange-400 group-hover:text-orange-500"
                            >
                                ⚡
                            </motion.span>
                        </motion.button>
                    </motion.div>
                )}
            </div>

            <div className="w-full max-w-2xl px-2 md:px-4">
                <p className="text-sm text-gray-500 mb-2 md:mb-4 text-center">Try these examples:</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
                    {examplePrompts.map((prompt, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="cursor-pointer p-3 md:p-4 bg-white rounded-xl border border-gray-200 hover:border-emerald-300 hover:shadow-md transition-all duration-200 h-[100px] md:h-[120px] flex flex-col justify-between"
                            onClick={() => handleExampleClick(prompt.text)}
                        >
                            <div className="flex items-center gap-2 md:gap-3">
                                <span className="text-xl md:text-2xl">{prompt.icon}</span>
                                <span className="text-[10px] md:text-xs font-medium text-emerald-600">{prompt.category}</span>
                            </div>
                            <p className="mt-1 md:mt-2 text-xs md:text-sm text-gray-700">{prompt.text}</p>
                        </motion.div>
                    ))}
                </div>
            </div>

            <AnimatePresence>
                <PromptTipsModal 
                    isOpen={promptTipsOpen} 
                    onClose={() => setPromptTipsOpen(false)} 
                />
            </AnimatePresence>
        </motion.div>
    );
}

export default function ChatBot( ) {
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { currentChatName, setCurrentChatName } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);
    const { setEnableChatInput } = React.useContext(UserContext);

    const { model } = React.useContext(UserContext);

    React.useEffect(() => {
        let interval;
      
        interval = setInterval(() => {
            console.log('Checking... Messages length:', messages.length);
        }, 3000); // Run every 5 seconds
      
        return () => clearInterval(interval); // Clear the interval when the component unmounts
      }, [messages, currentChatId]);

    const createChat = async ({ firstMessage }) => {
        
        if (messageIsLoading){
            return; // Prevent multiple submissions
        }

        // Validate message length
        if (calcMaxInputLength(model, firstMessage.length)) {
            alert(`Your message is too long. Please try again with a shorter message. Length: ${firstMessage.length}`);
            return;
        }

    
        try {
            // Step 1: Generate chat name
            const generatedChatNameResult = await createChatName(firstMessage);
            console.log('[CreateChat] Generated chat name:', generatedChatNameResult);
            const generatedChatName = generatedChatNameResult.chat_name;
            setCurrentChatName(generatedChatName);
            
            // Step 2: Update messages with the first message
            setMessages([{ role_id: 1, content: firstMessage }]);
            setMessageIsLoading(true); // Show loading state
    
            // Step 3: Create a new conversation
            const createConversationResult = await createConversation(userId, generatedChatName);
            console.log('[CreateChat] Created conversation:', createConversationResult);
            console.log('[CreateChat] Messages for conversation:', messages);
            const chatId = createConversationResult.chatId;
            console.log('[CreateChat] Chat ID:', chatId);
            setCurrentChatId(chatId);
    
            // Step 4: Make the SparkAI call
            const sparkaiCallResult = await SparkAICall(firstMessage, [{ role_id: 1, content: firstMessage }], model, chatId);
            console.log('[CreateChat] SparkAI call result:', sparkaiCallResult);

            if (sparkaiCallResult.error){
                console.log('[CreateChat] 1. SparkAI call error:', sparkaiCallResult.error);
                return;
            }
    
            // Update messages with SparkAI response
            setMessages(prevMessages => [
                ...prevMessages,
                { role_id: 2, content: sparkaiCallResult.text },
            ]);
        } catch (error) {
            console.error('[CreateChat] Error:', error);
            alert('An error occurred while processing your request. Please try again.');
        } finally {
            setMessageIsLoading(false); // Reset loading state
        }
    };
    

    const messagesLength = messages.length;
    React.useEffect(() => {

        if (messagesLength > 0) {
            setEnableChatInput(true);
        } else {
            setEnableChatInput(false);
        }
    }, [messages]);

    React.useEffect(() => {
        if (messageIsLoading) {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then((response) => {
                console.log('[ChatBot] response: ', response);
                setMessages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId, messageIsLoading]);

    /**
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (currentChatId) {
                axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
                    .then((response) => {
                        console.log('[ChatBot] response: ', response);
                        if (response.data.length > messages.length) {
                            setMessages(response.data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching messages:', error);
                    });
            }
        }, 5000); // 5 seconds

        return () => clearInterval(interval);
    }, [currentChatId]);
    */
    

    return (
        <>
        {messagesLength > 0 || messageIsLoading ? <MessagesList messages={messages} /> : <NoMessages createChat={createChat}/>}
        </>
    );
}